import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import PageRoutes from './helpers/PageRoutes';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);
    this.state = {};
  }
  
  render () {
    return (
      <BrowserRouter>
        <Route component={PageRoutes} />
      </BrowserRouter>
    );
  }
}
