import React, { Component } from 'react';
import axios from 'axios';
import { CenterPage, FullTable, PageTitle, TextBox, Button, LeftTD, RightTD, MidHeader, CoinAmountSmall, CoinFontSmall } from '../helpers/LayoutComponents';
import QrReader from 'modern-react-qr-reader';
import LoginState from '../helpers/LoginState';
import Alert from '@mui/material/Alert';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RuleIcon from '@mui/icons-material/Rule';

export default class SendCoins extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCam: false,
      facingMode: "environment",
      validReceiver: false,
      balance: '',
      receiver: ''
    }

    this.handleError = this.handleError.bind(this);
    this.handleScan = this.handleScan.bind(this);
    this.getBalance();
  }

  async getBalance() {
    const response = axios.get('../getbalance', {
      params: { pUserName: LoginState.userName }
    });
    const resultMessage = ((await response).data).toString();
    
    // respond after update
    this.setState({ balance: resultMessage });
  }

  showCamera() {
    this.setState({ showCam: !this.state.showCam });
  }

  changeCameraDirection() {
    this.setState({ facingMode: (this.state.facingMode === "environment" ? "user" : "environment") });
  }

  handleScan = data => {
    if (data) {
      this.CheckForExisting(data);
      this.setState({ receiver: data, showCam: false });
    }
  }
  
  handleError = err => {
    console.error(err)
  }

  captureInput = e => {
    if(e.target.name === 'receiver' && e.target.value.length > 3) {
      this.CheckForExisting(e.target.value);
    }
    this.setState({[e.target.name]: e.target.value, successMsg: '', errorMsg: ''});
  };

  async CheckForExisting(name) {
    const response = axios.get('../checkusername', {
      params: { pUserName: name }
    });
    const resultMessage = ((await response).data).toString();
    if(resultMessage)
      this.setState({ validReceiver: true });
    else
      this.setState({ validReceiver: false });
  }


  async requestCoinTransfer() {
    // validation
    if(!this.state.amount || !parseFloat(this.state.amount)) {
      this.setState({ successMsg: "", errorMsg: "Error: Invalid Number of Coins for Transfer" });
      return;
    }
    else if (parseFloat(this.state.amount) <= 0) {
      this.setState({ successMsg: "", errorMsg: "Error: Invalid Number of Coins for Transfer" });
      return;
    }
    else if (parseFloat(this.state.amount) >= parseFloat(this.state.balance.replaceAll(",", ""))) {
      this.setState({ successMsg: "", errorMsg: "Error: Transfer Amount Exceeds Available Balance" });
      return;
    }

    const response = axios.get('../sendcoins', {
      params: { pUserName: LoginState.userName, pAmount: this.state.amount, pSendTo: this.state.receiver }
    });
    const resultMessage = ((await response).data).toString();
    if(resultMessage === "Success") {
      this.setState({ successMsg: "Coins Successfully Transfered!", errorMsg: "", receiver: "", amount: "" });
      this.getBalance();
    }
    else
      this.setState({ successMsg: "", errorMsg: "Error: " + resultMessage });
  }

  render () {
    return (
      <CenterPage>
        <FullTable><tbody>
          <tr><td colSpan='3'>
            <br/>
            <PageTitle>Send Coins</PageTitle>
            <br/>
            <MidHeader>@{LoginState.userName}</MidHeader>
            <br/>
          </td></tr>
          <tr>
            <RightTD width='120px'>
              <MidHeader>Available Balance</MidHeader>
            </RightTD>
            <td width='10px' />
            <LeftTD width='180px'>
              <CoinAmountSmall>{this.state.balance}</CoinAmountSmall>
              <CoinFontSmall>MPNY</CoinFontSmall>
            </LeftTD>
          </tr>
          <tr>
            <RightTD>Number of Coins:</RightTD>
            <td width='10px' />
            <LeftTD><TextBox width='130px' type='number' name="amount" 
              value={this.state.amount} placeholder='MPNY (min .001)' maxLength='20'
              onChange={this.captureInput} onKeyPress={this.detectEnterKey} /></LeftTD>
          </tr>
          <tr>
            <RightTD>Send To:</RightTD>
            <td width='10px' />
            <LeftTD><TextBox width='130px' name="receiver" 
              value={this.state.receiver} placeholder='User Name' maxLength='25'
              onChange={this.captureInput} onKeyPress={this.detectEnterKey} />
              <Button variant="outlined" onClick={() => this.showCamera()} width="34px" style={{ marginLeft:'8px' }}>
                <QrCodeScannerIcon style={{ fontSize: '20px', height: '28px', color: 'black', position: 'relative', top: '-2px' }} />
              </Button>
              &nbsp;
              {this.state.receiver.length < 4 ? (
                <RuleIcon fontSize="medium" style={{ marginLeft:'8px' }} />
              ) : (
                <>
                  {this.state.validReceiver ? (
                    <VerifiedIcon fontSize="medium" style={{ marginLeft:'8px', color:'green' }} />
                  ) : (
                    <ErrorOutlineIcon fontSize="medium" style={{ marginLeft:'8px', color:'red' }} />
                  )}
                </>
              )}
            </LeftTD>
          </tr>

          <tr>
            <td colSpan='3'>
              <Button variant="outlined" onClick={() => this.requestCoinTransfer()} width="120px" 
                disabled={!this.state.validReceiver || this.state.receiver.length < 4}>
                Send Coins
              </Button><br/>
              {this.state.errorMsg && <Alert severity="error" sx={{ my: 2 }} onClose={() => this.setState({ errorMsg: '' })} >{this.state.errorMsg}</Alert>}
              {this.state.successMsg && <Alert severity="info" sx={{ my: 2 }} onClose={() => this.setState({ successMsg: '' })} >{this.state.successMsg}</Alert>}
            </td>
          </tr>
          {this.state.showCam && (<>
            <tr height='10px' />
            <tr>
              <td colSpan='3' style={{ background: '#444444' }}>
                <QrReader
                  delay={300}
                  facingMode={this.state.facingMode}
                  onError={this.handleError}
                  onScan={this.handleScan}
                  style={{ width: '100%', margin: 0, padding: 0 }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan='3'>
              <Button variant="outlined" onClick={() => this.changeCameraDirection()} width="34px">
                <CameraswitchIcon style={{ fontSize: '20px', height: '28px', color: 'black', position: 'relative', top: '-2px' }} />
              </Button>
              </td>
            </tr>
          </>)}
        </tbody></FullTable>
      </CenterPage>
    );
  }
}
