import React, { Component } from 'react';
import { CenterPage, FullTable, CoinFontSmall, CoinAmountSmall, MidHeader, 
  PageTitle, TextBox, Button, LeftTD, RightTD, Error, Success } from '../helpers/LayoutComponents';
import axios from 'axios';
import LoginState from '../helpers/LoginState';

export default class PurchaseCoins extends Component {

  constructor(props) {
    super(props);

    this.state = {
      balance: '',
      amount: ''
    }
    
    this.getBalance();
  }

  async getBalance() {
    const response = axios.get('../getbalance', {
      params: { pUserName: LoginState.userName }
    });
    const resultMessage = ((await response).data).toString();
    
    // respond after update
    this.setState({ balance: resultMessage });
  }

  captureInput = e => {
    if(e.target.name === 'sendTo') {
      this.CheckForExisting(e.target.value);
    }
    this.setState({[e.target.name]: e.target.value});
  };

  detectEnterKey = e => {
    if(e.charCode === 13)
      this.handleSubmit();
  }

  async handleSubmit() {
    // validate number of coins to send
    var floatAmt = parseFloat(this.state.amount);
    if(this.state.amount.length === 0 || this.state.amount.includes('e') || isNaN(floatAmt)) {
      this.setState({errorMsg: 'Please Enter a Valid Number of MonetPennies To Send!', successMsg: ''});
      return;
    }
    if(floatAmt <= 0) {
      this.setState({errorMsg: 'The Number of MonetPennies Must Be a Positive Number!', successMsg: ''});
      return;
    }
    var splitDecimal = this.state.amount.split('.');
    if(splitDecimal.length === 2 && splitDecimal[1].length > 3) {
      this.setState({errorMsg: 'The Minimum Fractional Amount Allowed Is Thousandths!', successMsg: ''});
      return;
    }

    // if validation has passed so far, we will try sending it over to the server
    const response = axios.get('../purchasecoins', {
      params: {
        pUserName: LoginState.userName,
        pAmount: floatAmt.toString(),
      }
    });
    const resultMessage = ((await response).data).toString();
    
    // respond after update
    if(resultMessage === "Success") {
      this.setState({
        successMsg: 'Coins Successfully Purchased!',
        errorMsg: '',
        amount: '',
        sendTo: '',
        hash: '',
        code: ''
      });
      this.getBalance();
    }
    else {
      this.setState({
        successMsg: '',
        errorMsg: resultMessage
      })
    }
  }

  render () {
    return (
      <CenterPage>
        <FullTable><tbody>
          <tr><td colSpan='3'>
            <br/>
            <PageTitle>Purchase Coins</PageTitle>
            <br/>
            <MidHeader>@{LoginState.userName}</MidHeader>
            <br/>
          </td></tr>
          <tr>
            <td colSpan='3'><hr/></td>
          </tr>
          <tr>
            <RightTD width='170px'>
              <MidHeader>Available Balance</MidHeader>
            </RightTD>
            <td width='10px' />
            <LeftTD width='170px'>
              <CoinAmountSmall>{this.state.balance}</CoinAmountSmall>
              <CoinFontSmall>MPNY</CoinFontSmall>
            </LeftTD>
          </tr>
          <tr height='10px' />
          <tr>
            <RightTD>Number of Coins:</RightTD>
            <td width='10px' />
            <LeftTD><TextBox width='130px' type='number' name="amount" 
              value={this.state.amount} placeholder='MPNY (minimum .001)' maxLength='20'
              onChange={this.captureInput} onKeyPress={this.detectEnterKey} /></LeftTD>
          </tr>
          
          <tr height='10px' />
          <tr>
            <RightTD>
              <Button onClick={() => this.handleSubmit()}>Purchase</Button>
            </RightTD>
            <td width='10px' />
            <LeftTD>
              <Button onClick={() => this.props.history.push("/wallet")}>Cancel</Button>
            </LeftTD>
          </tr>
          <tr height='10px' />
          <tr>
            <td colSpan='3'>
              <Error>{this.state.errorMsg}</Error>
              <Success>{this.state.successMsg}</Success>
            </td>
          </tr>
        </tbody></FullTable>
      </CenterPage>
    );
  }
}
