import React, { Component } from 'react';
import axios from 'axios';
import ImageGallery from 'react-image-gallery';
import { ModalMenu } from './ModalMenu';
import { fileDataToUrl } from '../helpers/utils';
import LeftNav from '../helpers/LeftNav';
import RightNav from '../helpers/RightNav';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Spinner from '../graphics/ColorBlob.svg';
import MenuIcon from '@mui/icons-material/Menu';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import SearchIcon from '@mui/icons-material/Search';
import MonetPenny from '../graphics/MonetPenny.png';
import "./ImageGallery.css";
import { ArtTitle, CornerLogo, CornerMenu, LoadButton, SearchButton } from '../helpers/LayoutComponents';

export default class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      imageData: [],
      showMenu: false,
      searchClicked: false,
      isLoading: true,
      isFullScreen: false,
      displayIndex: 0
    };

    // bind so that child component can call without issue
    this.navigateToPage = this.navigateToPage.bind(this);
  }
  componentDidMount() {
    // get images
    this.retrieveArtData();
  }

  captureInput = e => {
    this.setState({[e.target.name]: e.target.value});
  }

  base64ArrayBuffer(arrayBuffer) {
    var base64    = '';
    var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    var bytes         = new Uint8Array(arrayBuffer);
    var byteLength    = bytes.byteLength;
    var byteRemainder = byteLength % 3;
    var mainLength    = byteLength - byteRemainder;
  
    var a, b, c, d;
    var chunk;
  
    // Main loop deals with bytes in chunks of 3
    for (var i = 0; i < mainLength; i = i + 3) {
      // Combine the three bytes into a single integer
      chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];
  
      // Use bitmasks to extract 6-bit segments from the triplet
      a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
      b = (chunk & 258048)   >> 12; // 258048   = (2^6 - 1) << 12
      c = (chunk & 4032)     >>  6; // 4032     = (2^6 - 1) << 6
      d = chunk & 63;               // 63       = 2^6 - 1
  
      // Convert the raw binary segments to the appropriate ASCII encoding
      base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }
  
    // Deal with the remaining bytes and padding
    if (byteRemainder === 1) {
      chunk = bytes[mainLength];
  
      a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2
  
      // Set the 4 least significant bits to zero
      b = (chunk & 3)   << 4; // 3   = 2^2 - 1
  
      base64 += encodings[a] + encodings[b] + '==';
    } else if (byteRemainder === 2) {
      chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];
  
      a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
      b = (chunk & 1008)  >>  4; // 1008  = (2^6 - 1) << 4
  
      // Set the 2 least significant bits to zero
      c = (chunk & 15)    <<  2; // 15    = 2^4 - 1
  
      base64 += encodings[a] + encodings[b] + encodings[c] + '=';
    }
    
    return base64;
  }

  fileDataToUrl(uintarr) {
    var binary = atob(uintarr);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)]);
    const url = URL.createObjectURL(file);
    return url;
  }

  async retrieveArtData() {
    this.setState({isLoading: true});
    var i;
    var artIDs = "";
    for(i=0; i<this.state.imageData.length; i++) {
      artIDs = artIDs + this.state.imageData[i].artworkID + " ";
    }

    var response = axios.get('/getartwork', { 
      params: { 
        pArtworkList: (this.props.match.params.id === "random" ? artIDs : ""), 
        pArtworkID: (this.props.match.params.id === "random" ? "" : this.props.match.params.id) 
      } 
    });
    try {
      var resultData = (await response).data;

      // get images here and display
      var picList = this.state.images;
      var picDataList = this.state.imageData; 
      for(i=0; i<resultData.length; i++) {
        var pics = resultData[i].pictures;
        var urlLink = fileDataToUrl(pics[0]);
        picList.push({ original: urlLink, thumbnail: urlLink });
        picDataList.push({
          title: resultData[i].title,
          artist: resultData[i].artist,
          description: resultData[i].description,
          coins: resultData[i].coins,
          yearCreated: resultData[i].yearCreated,
          dateTransaction: resultData[i].dateTransaction,
          artworkID: resultData[i].artworkID
        });
      }
      this.setState({
        images: picList,
        imageData: picDataList,
        infoMsg: '',
        isLoading: false
      });
    } 
    catch {
      this.setState({
        images: [],
        imageData: [],
        infoMsg: '',
        isLoading: false
      });
    }
  }

  renderLeftNav(onClick, disabled) {
    return (<LeftNav onClick={onClick} disabled={disabled} />);
  }

  renderRightNav(onClick, disabled) {
    return (<RightNav onClick={onClick} disabled={disabled} />);
  }

  onImageClick(event) {
    console.log('clicked on image ' + this._imageGallery.getCurrentIndex());
  }

  onSlideChange(index) {
    this.setState({displayIndex: index});
  }

  showMenu() {
    this.setState({showMenu: true});
  }

  navigateToPage(pageName) {
    this.setState({showMenu: false, didScroll: false });
    if(pageName === "close") { return; }
    else { this.props.history.push(pageName.split('#')[0], pageName.split('#')[1]); }
  }

  render() {
    return (
      <Container>
        {this.state.showMenu && (<ModalMenu navigateTo={this.navigateToPage} />)}
        <CornerMenu>
          <MenuIcon style={{fontSize: '40px'}} onClick={()=> this.showMenu()} />
        </CornerMenu>

        {(this.state.imageData.length > 0) && (<>
          <CornerLogo>
            <img src={MonetPenny} alt="" width="40px" />
          </CornerLogo>
          <ArtTitle>
            {this.state.imageData[this.state.displayIndex].artist} &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp; {this.state.imageData[this.state.displayIndex].yearCreated}
            <br/>
            {this.state.imageData[this.state.displayIndex].title}
          </ArtTitle>

          {this.props.match.params.id === "random" && (
            <LoadButton>
              {this.state.isLoading ? (<>
                <img src={Spinner} alt="" width="50px" />
              </>) : (
              <div onClick={()=> this.retrieveArtData()}>
                <DynamicFeedIcon style={{fontSize: '40px'}} /> Load More
              </div>)}
            </LoadButton>
          )}
        </>)}

        <SearchButton>
          <div onClick={()=> this.props.history.push("/search")}>
            <SearchIcon style={{fontSize: '40px'}} /> Search Art
          </div>
        </SearchButton>

        <Box sx={{ mx: '0' }} style={{position:'relative', top:'70px'}}>
          {(!this.state.isLoading || this.state.imageData.length > 0) ? (
            <ImageGallery 
              ref={i => this._imageGallery = i}
              items={this.state.images}
              showPlayButton={false}
              showFullscreenButton={false}
              showBullets={this.state.images.length > 1}
              renderLeftNav={this.renderLeftNav}
              renderRightNav={this.renderRightNav}
              onClick={this.onImageClick.bind(this)}
              onSlide={this.onSlideChange.bind(this)}
            />
          ) : (
            <div style={{ width: '100%', textAlign: 'center', position: 'relative', top: '75px' }}>
              <img src={Spinner} alt="" width="100px" />
            </div>
          )}
        </Box>
      </Container>
    );
  }
}
