import { InlineBody, InlineCard, InlineHeader, InlineWrapper, InlineClose, FullScreenToggle } from './PopupInlineElements';
import { LeftTD, RightTD, HeaderTable } from './LayoutComponents';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FileViewer from "react-file-viewer";
import React, { Component } from 'react';

export default class InlineDisplay extends Component {  
  constructor(props) {
    super(props);

    this.state = {
      isFullScreen: false
    };
  }

  toggleFullScreen() {
    this.setState({isFullScreen: !this.state.isFullScreen});
  }

  onError() {
    console.log("Error Loading Preview File");
  }

  fileTypeValid() {
    if(!this.props.fileExt)
      return false;
    var compare = this.props.fileExt.toLowerCase();
    switch(compare) {
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
      case "bmp":
      case "pdf":
      case "csv":
      case "xlsx":
      case "docx":
      case "mp4":
      case "webm":
      case "mp3":
        return true;
      default:
        return false;
    }
  }

  render() {
    return this.props.show ? (
      <InlineWrapper>
        <InlineCard fullscreen={this.state.isFullScreen}>
          <InlineHeader>
            <HeaderTable><tbody>
              <tr>
                <LeftTD style={{paddingLeft: '5px'}}>{this.props.title}</LeftTD>
                <RightTD width="30px" style={{paddingRight: '5px'}}>
                  <InlineClose>
                    <CancelPresentationIcon size="2rem" onClick={this.props.onClose} />
                  </InlineClose>
                </RightTD>
              </tr>
            </tbody></HeaderTable>
          </InlineHeader>
          <InlineBody fullscreen={this.state.isFullScreen}>
            {(this.props.previewSrc && this.fileTypeValid()) ? (
              <FileViewer fileType={this.props.fileExt} filePath={this.props.previewSrc} onError={() => this.onError()} width="100%" height="100%" />
            ) : (
              <div style={{textAlign: "center"}}><br/>Error: FileViewer Does Not Support the File Type for the Specified File</div>
            )}
          </InlineBody>
          <table><tbody>
            <tr>
              <td/>
              <RightTD width="30px" onClick={() => this.toggleFullScreen()}>
                <FullScreenToggle>{this.state.isFullScreen ? (<FullscreenExitIcon />) : (<FullscreenIcon />)}</FullScreenToggle>
              </RightTD>
            </tr>
          </tbody></table>
        </InlineCard>
      </InlineWrapper>
    ) : null;
  }
}