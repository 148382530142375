import React, { Component } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MonetPenny from '../graphics/MonetPenny.png';
import SolanaIcon from '../graphics/SolanaIcon.png';
import SerumIcon from '../graphics/SerumIcon.png';
import PhantomIcon from '../graphics/PhantomIcon.png';

export default class Resources extends Component {
  render() {
    return (
      <Container>
        <Box sx={{ mx: 'auto', mb: 2 }}>
          <Typography variant="h4" sx={{ fontWeight: 'medium', fontFamily: 'Satisfy', mb: 2, mt: 4, textAlign: 'left' }}>
            <img src={MonetPenny} alt="" width="60px" style={{ marginRight: '20px' }} />
            Resources
          </Typography>
          <hr/>
          <Grid container gap={2}>
            <Box sx={{ maxWidth: '350px', mx: 'auto', mt:2, border: 1, borderRadius: 2, 
              background: 'linear-gradient(to right bottom, #333333, #eeeeee, #555555)', color: 'black', borderColor: 'blue', p: 4 }}>
              <Grid container>
                <img src={SolanaIcon} alt="" width="50px" style={{ marginRight: '10px' }} />
                <a href="https://solana.com/" target="_blank" rel="noopener noreferrer">
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mt: '7px', textAlign: 'left' }}>Solana</Typography>
                </a>
                <br/>
                <Typography variant="subtitle2" sx={{ fontWeight: 'medium', mt: '10px', mb: '20px', textAlign: 'left' }}>
                  Cryptocurrency, Blockchain Technology
                </Typography>
                Solana is a public blockchain platform with smart contract functionality. Its native cryptocurrency 
                is SOL. Solana claims to offer faster transaction times and lower costs than its main competitor, Ethereum.
              </Grid>
            </Box>

            <Box sx={{ maxWidth: '350px', mx: 'auto', mt:2, border: 1, borderRadius: 2, 
              background: 'linear-gradient(to right bottom, #333333, #eeeeee, #555555)', color: 'black', borderColor: 'blue', p: 4 }}>
              <Grid container >
                <img src={SerumIcon} alt="" width="50px" style={{ marginRight: '10px' }} />
                <a href="https://www.projectserum.com/" target="_blank" rel="noopener noreferrer">
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mt: '7px', textAlign: 'left' }}>Serum</Typography>
                </a>
                <br/>
                <Typography variant="subtitle2" sx={{ fontWeight: 'medium', mt: '10px', mb: '20px', textAlign: 'left' }}>
                  Platform to Buy, Sell, Trade (MonetPenny)
                </Typography>
                Serum is a protocol and ecosystem that brings unprecedented speed and low transaction costs to decentralized 
                finance. It is built on Solana and is completely permissionless. Ecosystem partners can compose with Serum’s 
                on-chain central limit orderbook to share liquidity and power markets-based features for institutional and 
                retail users.
              </Grid>
            </Box>

            <Box sx={{ maxWidth: '350px', mx: 'auto', mt:2, border: 1, borderRadius: 2, 
              background: 'linear-gradient(to right bottom, #333333, #eeeeee, #555555)', color: 'black', borderColor: 'blue', p: 4 }}>
              <Grid container >
                <img src={PhantomIcon} alt="" width="50px" style={{ marginRight: '10px' }} />
                <a href="https://phantom.app/" target="_blank" rel="noopener noreferrer">
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mt: '7px', textAlign: 'left' }}>Phantom Wallet</Typography>
                </a>
                <br/>
                <Typography variant="subtitle2" sx={{ fontWeight: 'medium', mt: '10px', mb: '20px', textAlign: 'left' }}>
                  Digital Wallet for Cryptocurrency
                </Typography>
                Phantom is a digital wallet tailored for Solana and Ethereum, reimagined for DeFi, making it safe and easy to 
                store, send, receive, collect, and swap tokens on the Solana blockchain. Store your MonetPenny coins on Phantom!
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Container>
    );
  }
}
