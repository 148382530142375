import React, { Component } from 'react';
import { CenterPage, FullTable, CoinFontSmall, CoinAmountSmall, MidHeader, PageTitle, TextBox, Select, 
  Button, SmallLink, SmallText, SearchText, ActivityRow, TableHeader, LeftTD, RightTD, CenterTD, Error, Success } from '../helpers/LayoutComponents';
import axios from 'axios';
import LoginState from '../helpers/LoginState';
import InlineDisplay from '../helpers/InlineDisplay';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AddchartIcon from '@mui/icons-material/Addchart';
import XLSX from "xlsx";
import ReactPaginate from 'react-paginate';
import '../helpers/Paginate.css';

export default class Activity extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searchResults: [],
      pageCount: 1,
      pageNum: 0,
      transactionOpen: [],
      successMsg: '',
      errorMsg: '',
      balance: '',
      searchDisplay: false,
      searchCategories: [
        { label: 'All Categories', value: 0 },
        { label: 'Purchased Coins', value: 1 },
        { label: 'Sent Coins', value: 2 },
        { label: 'Received Coins', value: 3 },
      ],
      keywords: '',
      category: '',
      minAmount: '',
      maxAmount: '',
      minDate: '',
      maxDate: '',
      keywordsSearch: '',
      categorySearch: '',
      minAmountSearch: '',
      maxAmountSearch: '',
      minDateSearch: '',
      maxDateSearch: '',
      showModal: false
    }
    this.getBalance();
    this.handleSearch(0);
  }

  async getBalance() {
    const response = axios.get('../getbalance', {
      params: { pUserName: LoginState.userName }
    });
    const resultMessage = ((await response).data).toString();
    
    // respond after update
    this.setState({ balance: resultMessage });
  }

  captureInput = e => {
    if(e.target.name === 'sendTo') {
      this.CheckForExisting(e.target.value);
    }
    this.setState({[e.target.name]: e.target.value});
  };

  detectEnterKey = e => {
    if(e.charCode === 13)
      this.handleSearch(0);
  }

  cancelSearch() {
    this.setState({
      searchDisplay: false,
      keywords: '',
      category: 0,
      minAmount: '',
      maxAmount: '',
      minDate: '',
      maxDate: ''
    });
  }

  async handleSearch(pageNum) {
    // todo: validate search params
    /* var floatAmt = parseFloat(this.state.amount);
    if(this.state.amount.length === 0 || this.state.amount.includes('e') || isNaN(floatAmt)) {
      this.setState({errorMsg: 'Please Enter a Valid Number of ArtCoins To Send!', successMsg: ''});
      return;
    } */

    // if validation has passed so far, we will try sending it over to the server
    const response = axios.get('../searchfilter', {
      params: {
        pUserName: LoginState.userName,
        pKeywords: this.state.keywords,
        pCategory: this.state.category,
        pMinAmount: this.state.minAmount,
        pMaxAmount: this.state.maxAmount,
        pMinDate: this.state.minDate,
        pMaxDate: this.state.maxDate,
        pPage: pageNum
      }
    });
    const resultMessage = (await response).data;
    if(resultMessage.results) {
      var pc = Math.floor(parseInt(resultMessage.count) / 50) + 1;
      this.setState({
        searchDisplay: false,
        searchResults: resultMessage.results,
        pageCount: pc,
        transactionOpen: [],
        pageNum: resultMessage.page,
        errorMsg: '',
        keywordsSearch: this.state.keywords,
        categorySearch: this.state.category,
        minAmountSearch: this.state.minAmount,
        maxAmountSearch: this.state.maxAmount,
        minDateSearch: this.state.minDate,
        maxDateSearch: this.state.maxDate,
      });
    }
    else {
      this.setState({ errorMsg: 'An Error Occurred While Retrieving Data!' })
    }
  }

  downloadTxtFile() {
    // first parse/create text content
    var txt = '';
    for(var i=0; i<this.state.searchResults.length; i++) {
      var sr = this.state.searchResults[i];
      txt = txt + "- " + sr.timestamp + " (" + sr.description + ")\n" +
        "  Amount: " + sr.amount + (parseFloat(sr.fee) > 0 ? "Fee: " + sr.fee : "") + 
        " Balance: " + sr.balance + "\n"; 
    }
    const element = document.createElement("a");
    const file = new Blob([txt], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "activity.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);
  }

  downloadExcelFile() {
    // first parse/create excel content
    const ws = XLSX.utils.json_to_sheet(this.state.searchResults);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Activity');
    XLSX.writeFile(wb, 'activity.xlsx');
  }

  getPrintOptions() {
    return "cat:" + this.state.categorySearch + ",minA:" + this.state.minAmountSearch + ",maxA:" + this.state.maxAmountSearch + 
      ",minD:" + this.state.minDateSearch + ",maxD:" + this.state.maxDateSearch + ",key:" + this.state.keywordsSearch.replace(',', '!comma!').replace(':', '!colon!');
  }

  async downloadVerificationHash() {
    const response = axios.get('../validationhash', {
      params: { pUserName: LoginState.userName }
    });
    const resultMessage = (await response).data;

    const element = document.createElement("a");
    const file = new Blob([resultMessage], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "validation-" + LoginState.userName + ".vhf";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);
  }

  handlePageClick = e => {
    if(this.state.pageNum !== e.selected) // todo: make sure that changing != to !== didn't break anything (test)
      this.handleSearch(e.selected);
  }

  openTransaction(index) {
    var to = this.state.transactionOpen;
    to[index] = to[index] ? false : true;
    this.setState({ transactionOpen: to });
  }

  render () {
    return (
      <CenterPage>
        <FullTable><tbody>
          <tr><td colSpan='3'>
            <br/>
            <PageTitle>Activity</PageTitle>
            <br/>
            <MidHeader>@{LoginState.userName}</MidHeader>
            <br/>
          </td></tr>
          <tr>
            <td colSpan='3' style={{paddingLeft:'10px', paddingRight:'10px'}}><hr style={{border: '1px solid gray'}} /></td>
          </tr>
          <tr>
            <RightTD width='150px'>
              <MidHeader>Available Balance</MidHeader>
            </RightTD>
            <td width='10px' />
            <LeftTD width='150px'>
              <CoinAmountSmall>{this.state.balance}</CoinAmountSmall>
              <CoinFontSmall>MPNY</CoinFontSmall>
            </LeftTD>
          </tr>
          <tr>
            <td colSpan='3' style={{paddingLeft:'10px', paddingRight:'10px'}}><hr style={{border: '1px solid gray'}} /></td>
          </tr>
        </tbody></FullTable>
        <FullTable><tbody>
          <tr>
            <LeftTD style={{paddingLeft: '10px'}}><SmallText>{this.state.pageCount > 1 && (<>(50 Per Page)</>)} </SmallText></LeftTD>
            <RightTD style={{paddingRight: '10px'}}>
              <SmallLink onClick={() => this.setState({ searchDisplay: true })}><SearchIcon style={{ fontSize: 20, paddingRight: '3px' }} />Search</SmallLink>
              <SmallLink onClick={() => this.setState({ showModal: true })}><DownloadIcon style={{ fontSize: 20, paddingLeft: '3px', paddingRight: '3px' }} />Download</SmallLink> 
              <SmallLink onClick={() => window.open("/activityprint/" + this.getPrintOptions(), "_blank", "width=600,height=600")}>
                <PrintIcon style={{ fontSize: 20, paddingLeft: '3px', paddingRight: '3px' }} />Print View
              </SmallLink>
            </RightTD>
          </tr>
          <tr>
            <td colSpan='3' style={{paddingLeft:'10px', paddingRight:'10px'}}><hr style={{border: '1px solid gray'}} /></td>
          </tr>
        </tbody></FullTable>
        <FullTable><tbody>
          {this.state.searchDisplay ? (<>
            <tr><td colSpan='3'>
              <table style={{margin: '10px'}}><tbody>
                <tr>
                  <LeftTD><MidHeader>Search Parameters:</MidHeader></LeftTD>
                </tr>
                <tr height='10px' />
                <tr>
                  <LeftTD>
                    <SmallText>Keywords</SmallText><br/>
                    <TextBox width='145px' name="keywords" value={this.state.keywords} 
                      placeholder='Search For Keywords...' maxLength='100'
                      onChange={this.captureInput} onKeyPress={this.detectEnterKey} 
                      style={{marginRight: '10px'}} />
                  </LeftTD>
                  <LeftTD>
                    <SmallText>Category</SmallText><br/>
                    <Select width='145px' name="category" value={this.state.category} 
                      onChange={this.captureInput}>
                        <option key={0} value={0}>All Categories</option>
                        <option key={1} value={1}>Purchased Coins</option>
                        <option key={2} value={2}>Sent Coins</option>
                        <option key={3} value={3}>Received Coins</option>
                    </Select>
                  </LeftTD>
                </tr>
              </tbody></table>
              <table style={{margin: '10px'}}><tbody>
                <tr>
                  <LeftTD colSpan='3'>
                    <SmallText>Date From</SmallText><br/>
                    <TextBox type='date' width='145px' 
                      name="minDate" value={this.state.minDate}
                      onChange={this.captureInput} onKeyPress={this.detectEnterKey} 
                      style={{marginRight: '10px'}} />
                  </LeftTD>
                  <LeftTD colSpan='3'>
                    <SmallText>Date To</SmallText><br/>
                    <TextBox type='date' width='145px'
                      name="maxDate" value={this.state.maxDate}
                      onChange={this.captureInput} onKeyPress={this.detectEnterKey} />
                  </LeftTD>
                </tr>
              </tbody></table>
              <table style={{margin: '10px'}}><tbody>
                <tr>
                  <LeftTD colSpan='3'>
                    <SmallText>Amount From</SmallText><br/>
                    <TextBox type='number' width='145px' placeholder='MPNY' 
                      name="minAmount" value={this.state.minAmount}
                      onChange={this.captureInput} onKeyPress={this.detectEnterKey}
                      style={{marginRight: '10px'}} />
                  </LeftTD>
                  <LeftTD colSpan='3'>
                    <SmallText>Amount To</SmallText><br/>
                    <TextBox type='number' width='145px' placeholder='MPNY'
                      name="maxAmount" value={this.state.maxAmount}
                      onChange={this.captureInput} onKeyPress={this.detectEnterKey} />
                  </LeftTD>
                </tr>
              </tbody></table>
            </td></tr>
            <tr>
              <td colSpan='3'><hr style={{border: '1px solid gray'}} /></td>
            </tr>
            <tr><td colSpan='3'>
              <table style={{margin: '10px'}} width="100%"><tbody>
                <tr>
                  <CenterTD>
                    <Button width='80px' onClick={() => this.handleSearch(0)} style={{marginRight: '10px'}}>Search</Button>
                    <Button width='80px' onClick={() => this.cancelSearch()}>Cancel</Button>
                  </CenterTD>
                </tr>
              </tbody></table>
            </td></tr>
          </>) : (
            <tr>
              <CenterTD colSpan='3' style={{padding: '10px'}}>
                <table width="100%"><tbody>
                  {(this.state.keywordsSearch || this.state.categorySearch > 0 || this.state.minAmountSearch || this.state.maxAmountSearch ||
                    this.state.minDateSearch || this.state.maxDateSearch) && (
                    <>
                    <tr>
                      <LeftTD colSpan='7'><MidHeader>Search Results</MidHeader></LeftTD>
                    </tr>
                    <tr>
                      <LeftTD colSpan='7'>
                        {this.state.keywordsSearch && (
                          <SearchText>Keywords: ({this.state.keywords})<br/></SearchText>
                        )}
                        {this.state.categorySearch > 0 && (
                          <SearchText>Category: (
                            {this.state.categorySearch === '1' && ('Purchase Coins')}
                            {this.state.categorySearch === '2' && ('Sent Coins')}
                            {this.state.categorySearch === '3' && ('Received Coins')}
                          )<br/></SearchText>
                        )}
                        {this.state.minAmountSearch && (
                          <SearchText>Amount {'>='} {this.state.minAmountSearch}{!this.state.maxAmountSearch && (<br/>)}</SearchText>
                        )}
                        {this.state.maxAmountSearch && (
                          <SearchText>Amount {'<='} {this.state.maxAmountSearch}<br/></SearchText>
                        )}
                        {this.state.minDateSearch && (
                          <SearchText>Date {'>='} {this.state.minDateSearch}</SearchText>
                        )}
                        {this.state.maxDateSearch && (
                          <SearchText>Date {'<='} {this.state.maxDateSearch}</SearchText>
                        )}
                      </LeftTD>
                    </tr>
                    </>
                  )}
                  <tr height='10px' />
                  {this.state.searchResults.length > 0 ? (
                    <>
                      {this.state.pageCount > 1 && (
                        <tr><td colSpan={7}>
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={this.handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={this.state.pageCount}
                            previousLabel="< previous"
                            initialPage={this.state.pageNum}
                            renderOnZeroPageCount={null}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                          />
                        </td></tr>
                      )}
                      <ActivityRow height='35px' background='#999999' color='white'>
                        <td width='10px' />
                        <LeftTD><TableHeader>Transaction</TableHeader></LeftTD>
                        <td/>
                        <RightTD><TableHeader>Amount</TableHeader></RightTD>
                        <RightTD><TableHeader>Balance</TableHeader></RightTD>
                        <td/>
                        <td width='10px' />
                      </ActivityRow>
                      {this.state.searchResults.length > 0 &&
                        this.state.searchResults.map((activity) => (
                        <ActivityRow key={activity.transactionID}>
                          <td width='10px' />
                          <LeftTD>
                            <b>{activity.description}</b>
                            <br/>
                            {activity.timestamp}
                            {this.state.transactionOpen[activity.transactionID] && (
                              <>
                                <br/>
                                <table><tbody>
                                  <tr height='5px' />
                                  <tr>
                                    <td width='35px'>From: </td>
                                    <td>{activity.fromUser}</td>
                                  </tr>
                                  <tr>
                                    <td>To: </td>
                                    <td>{activity.toUser}</td>
                                  </tr>
                                  <tr>
                                    <td>Fee: </td>
                                    <td>{activity.fee}</td>
                                  </tr>
                                  <tr height='5px' />
                                </tbody></table>
                              </>
                            )}
                          </LeftTD>
                          <td></td>
                          <RightTD>
                            {activity.amount[0] !== '-' && '+'}{activity.amount} 
                            {activity.fee !== '0.000' && (<br/> + '-' + activity.fee)}
                          </RightTD>
                          <RightTD width='100px'>{activity.balance}</RightTD>
                          {this.state.transactionOpen[activity.transactionID] ? (
                            <RightTD width='30px'>
                              <RemoveCircleOutlineIcon 
                                style={{ fontSize: 20, color: '#999999', cursor: 'pointer', paddingLeft: '3px' }} 
                                onClick={ ()=> this.openTransaction(activity.transactionID) } />
                            </RightTD>
                          ) : (
                            <RightTD width='30px'>
                              <AddCircleOutlineIcon 
                                style={{ fontSize: 20, color: '#999999', cursor: 'pointer', paddingLeft: '3px' }} 
                                onClick={ ()=> this.openTransaction(activity.transactionID) } />
                            </RightTD>
                          )}
                          <td width='10px' />
                        </ActivityRow>
                      ))}
                    </>
                  ) : (
                    <>
                      <tr><td colSpan='3'><hr/></td></tr>
                      <tr><td colSpan='3'>No Results Found!</td></tr>
                    </>
                  )}
                </tbody></table>
              </CenterTD>
            </tr>
          )}
          <tr>
            <td colSpan='3'>
              <Error>{this.state.errorMsg}</Error>
              <Success>{this.state.successMsg}</Success>
            </td>
          </tr>
        </tbody></FullTable>

        <InlineDisplay show={this.state.showModal}
          onClose={() => this.setState({showModal: false})}
          width="300px"
          title="Download">
          <table width="100%" style={{ textAlign: 'center' }}><tbody>
            <tr height="10px" />
            <tr>
              <td><SmallLink onClick={() => this.downloadTxtFile()}>
                Download Text
                <TextSnippetIcon style={{ fontSize: 24, color: '#999999', cursor: 'pointer', paddingLeft: '3px' }} />
              </SmallLink></td>
              <td><SmallLink onClick={() => this.downloadExcelFile()}>
                Download Excel
                <ListAltIcon style={{ fontSize: 24, color: '#999999', cursor: 'pointer', paddingLeft: '3px' }} />
              </SmallLink></td>
            </tr>
            <tr height='10px' />
            <tr>
              <td colSpan='3'><SmallLink onClick={() => this.downloadVerificationHash()}>
                Download Verification Node
                <AddchartIcon style={{ fontSize: 24, color: '#999999', cursor: 'pointer', paddingLeft: '3px' }} />
              </SmallLink></td>
            </tr>
          </tbody></table>
        </InlineDisplay>
      </CenterPage>
    );
  }
}
