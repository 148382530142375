import React, { Component } from 'react';
import axios from 'axios';
import LoginState from '../helpers/LoginState';
import { Link } from 'react-router-dom';
import { CenterPage, FullTable, NavButton, CoinFont, CoinAmount, PageTitle, MidHeader } from '../helpers/LayoutComponents';

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      balance: '0.000'
    }
    this.getBalance();
  }

  async getBalance() {
    const response = axios.get('../getbalance', {
      params: { pUserName: LoginState.userName }
    });
    const resultMessage = ((await response).data).toString();
    
    // respond after update
    this.setState({ balance: resultMessage });
  }


  render () {
    return (
      <CenterPage>
        <FullTable><tbody>
          <tr><td>
            <br/>
            <PageTitle>Wallet Balance</PageTitle>
            <br/>
            <MidHeader>@{LoginState.userName}</MidHeader>
            <br/>
            <CoinAmount>{this.state.balance}</CoinAmount>
            <CoinFont>MPNY</CoinFont>
          </td></tr>
          <tr><td>
            <Link to="/activity" tabIndex="-1">
              <NavButton>Activity</NavButton>
            </Link>
          </td></tr>
          <tr height="15px" />
          <tr><td>
            <Link to="/send" tabIndex="-1">
              <NavButton>Send Coins</NavButton>
            </Link>
          </td></tr>
          <tr height="15px" />
          <tr><td>
            <Link to="/receive" tabIndex="-1">
              <NavButton>Receive Coins</NavButton>
            </Link>
          </td></tr>
          <tr height="15px" />
          <tr><td>
            <Link to="/purchase" tabIndex="-1">
              <NavButton>Purchase Coins</NavButton>
            </Link>
          </td></tr>
        </tbody></FullTable>
      </CenterPage>
    );
  }
}
