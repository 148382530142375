import React, { Component } from 'react';
import { CenterPage, FullTable, PageTitle, Button} from '../helpers/LayoutComponents';
import axios from 'axios';

export default class Validate extends Component {

  constructor(props) {
    super(props);

    this.state = { 
      isLoading: true,
      email: ''
    };

    this.LoadData();
  }

  async LoadData() {
    const response = axios.get('../validate', { 
      params: {
        pValidationKey: this.props.match.params.id
      } 
    });
    const resultMessage = ((await response).data).toString();
    if(resultMessage === 'Not Found') {
      // show success message and clear fields
      this.setState({isLoading: false, email: ''});
    }
    else {
      this.setState({isLoading: false, email: resultMessage});
    }
  }

  render() {
    return (
      <CenterPage>
        <FullTable><tbody>
          <tr><td colSpan='3'>
            <br/>
            <PageTitle>Validate Email</PageTitle>
            <br/>
          </td></tr>
          <tr><td>
            {this.state.isLoading ? (
              <div style={{textAlign: 'center'}}>
                Loading ...
              </div>
            ) : (
              <>
              {this.state.email ? (
                <div style={{textAlign: 'center'}}>
                  <br/><br/>
                  Thank you for validating the user account associated with email address: ({this.state.email})!
                  <br/><br/>
                  You are now able to start using your wallet to buy, send, and receive coins. Please login, to now
                  access your account!
                  <br/><br/>
                  <Button onClick={() => this.props.history.push("/login")}>Login</Button>
                </div>
              ) : (
                <div style={{textAlign: 'center'}}>
                  <br/><br/>
                  The validation key submitted was not found in the system. Either the key has already been used to 
                  activate your account, or the wrong key was provided to attempt validation.
                  <br/><br/>
                  <Button onClick={() => this.props.history.push("/login")}>Login</Button>
                </div>
              )}
              </>
            )}
          </td></tr>
        </tbody></FullTable>
      </CenterPage>
    );
  }
}
