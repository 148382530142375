import React, { Component } from 'react';
import { CenterPage, FullTable, PageTitle, Button, CenterDiv, Link, TextBox, TextArea, SpacerDiv, CenterTD } from '../helpers/LayoutComponents';
import { fileDataToUrl } from '../helpers/utils';
import Popup from '../helpers/Popup';
import InlineDisplay from '../helpers/InlineDisplay';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Dropzone from 'react-dropzone-uploader';

const MaxFileSize = 25000000;

export default class RegisterArt extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isSubmitting: false,
      showAttachment: false,
      selectedFileName: '',
      previewURL: '',
      fileExt: '',
      fileList: [],
      errorMsg: '',
      successMsg: '',
    };
  }

  componentDidMount() {
  }

  captureInput = e => {
    this.setState({[e.target.name]: e.target.value});
  }

  base64ArrayBuffer(arrayBuffer) {
    var base64    = '';
    var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    var bytes         = new Uint8Array(arrayBuffer);
    var byteLength    = bytes.byteLength;
    var byteRemainder = byteLength % 3;
    var mainLength    = byteLength - byteRemainder;
  
    var a, b, c, d;
    var chunk;
  
    // Main loop deals with bytes in chunks of 3
    for (var i = 0; i < mainLength; i = i + 3) {
      // Combine the three bytes into a single integer
      chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];
  
      // Use bitmasks to extract 6-bit segments from the triplet
      a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
      b = (chunk & 258048)   >> 12; // 258048   = (2^6 - 1) << 12
      c = (chunk & 4032)     >>  6; // 4032     = (2^6 - 1) << 6
      d = chunk & 63;               // 63       = 2^6 - 1
  
      // Convert the raw binary segments to the appropriate ASCII encoding
      base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }
  
    // Deal with the remaining bytes and padding
    if (byteRemainder === 1) {
      chunk = bytes[mainLength];
  
      a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2
  
      // Set the 4 least significant bits to zero
      b = (chunk & 3)   << 4; // 3   = 2^2 - 1
  
      base64 += encodings[a] + encodings[b] + '==';
    } else if (byteRemainder === 2) {
      chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];
  
      a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
      b = (chunk & 1008)  >>  4; // 1008  = (2^6 - 1) << 4
  
      // Set the 2 least significant bits to zero
      c = (chunk & 15)    <<  2; // 15    = 2^4 - 1
  
      base64 += encodings[a] + encodings[b] + encodings[c] + '=';
    }
    
    return base64;
  }

  handleChangeStatus({ file, remove }, status) {
    if(status === 'preparing') {
      this.setState({isSubmitting: true});
    }
    else if (status === 'done') {
      const reader = new FileReader();
      var fileToUpload;

      // validate file size
      if(file.size > MaxFileSize) {
        this.setState({ errorMsg: "File Size Cannot Exceed 25 Mb" });
        remove();
        setTimeout(() => { this.setState({ isSubmitting: false }) }, 500);
        return;
      }
      reader.readAsArrayBuffer(file);
      reader.onload = e => { fileToUpload = e.target.result; };
      reader.onloadend = () => {
        var uintarr = this.base64ArrayBuffer(fileToUpload);
        if(this.state.fileList.length < 10) {
          this.state.fileList.push({
            fileUIntArr: uintarr,
            name: file.name
          });
        }
        else {
          this.setState({ errorMsg: "Cannot Exceed the Maximum of 10 Files Allowed For Uploads" });
        }
        remove();
        setTimeout(() => { this.setState({ isSubmitting: false }) }, 2000);
      };
    }
  }

  inputContent = ({rejectedFiles}) => {
    if(this.state.isSubmitting === true) {
      return (
        <div>
          <br/>
          <br/>
          <span style={{color: 'black'}}>Loading File(s)... Please Wait!</span>
        </div>
      );
    }
    else {
      return (
        <div>
          <br/>
          <CloudUploadIcon style={{ color:"#3ec5cf" }} /><br/>
          <span style={{color: 'black'}}><b>Drag &amp; Drop Photos</b></span><br/>
          <span style={{color: 'black', fontSize:'13px'}}>(Maximum File Size 25 Mb)</span>
        </div>
      );
    }
  }
  
  showPreview(fileName, fileData) {
    const urlLink = fileDataToUrl(fileData);
    var res = fileName.split(".");
    this.setState({showAttachment: this.fileTypeValid(res[res.length-1]), selectedFileName: fileName, fileExt: res[res.length-1], previewURL: urlLink});
  }

  fileTypeValid(fileExt) {
    if(!fileExt)
      return false;
    var compare = fileExt.toLowerCase();
    switch(compare) {
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
      case "bmp":
        return true;
      default:
        return false;
    }
  }

  fileList() {
    if(this.state.fileList.length === 0)
      return (<></>);
    else return (
      <div style={{textAlign: 'left'}}>
        <br/>
        {this.state.fileList.map(file => (
          <div key={file.name}>
            {this.fileTypeValid(file.name.split(".")[file.name.split(".").length - 1].toLowerCase()) ? (
              <Link onClick={() => this.showPreview(file.name, file.fileUIntArr)}>
                <ArrowRightIcon />
                {file.name}
              </Link>
            ) : (
              <span>
                <ArrowRightIcon />
                {file.name}
              </span>
            )}
            
            &nbsp;
            <Link onClick={() => this.deleteAttachment(file)} color="#fc6a76">[Delete]</Link>
          </div>
        ))} 
        <SpacerDiv height="10px" />
      </div>
    );
  }

  deleteAttachment(fileToDelete) {
    var array = this.state.fileList;
    var index = array.indexOf(fileToDelete)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({fileList: array});
    }
  }

  combineBuffers(file1, file2) {
    return file1 + '$$$$$' + file2;
  }

  async registerArt() {
    // validate data
    if(!this.state.artist) {
      this.setState({errorMsg: 'Please Specify An Artist Name!'});
      return;
    }
    if(!this.state.title) {
      this.setState({errorMsg: 'Please Specify A Title For the Artwork!'});
      return;
    }
    if(!this.state.coins) {
      this.setState({errorMsg: 'Please Specify The Number of MonetPennies Paid!'});
      return;
    }
    if(!this.state.date) {
      this.setState({errorMsg: 'Please Specify A Date For the Artwork!'});
      return;
    }
    if(!this.state.transaction) {
      this.setState({errorMsg: 'Please Specify A Date For the Transaction!'});
      return;
    }
    if(this.state.fileList.length === 0) {
      this.setState({errorMsg: 'Please Include At Least 1 Image File!'});
      return;
    }

    // set message to show that we are uploading
    this.setState({
      isUploading: true,
      errorMsg: '',
      successMsg: 'Uploading Files - Please Wait!',
    });

    // first combine all the files and fileTypes into one.
    var combinedFiles = null;
    var combinedFileTypes = null;
    var fileName = null;
    for (var j = 0; j < this.state.fileList.length; j++) {
      if (j === 0) {
        combinedFiles = this.state.fileList[j].fileUIntArr;
        fileName = this.state.fileList[j].name;
        combinedFileTypes = fileName.split(".")[fileName.split(".").length - 1].toLowerCase();
      }
      else {
        combinedFiles = this.combineBuffers(
          combinedFiles,
          this.state.fileList[j].fileUIntArr
        );
        fileName = this.state.fileList[j].name;
        combinedFileTypes = this.combineBuffers(
          combinedFileTypes,
          fileName.split(".")[fileName.split(".").length - 1].toLowerCase()
        );
      }
    }

    const params = {
      artist: this.state.artist,
      title: this.state.title,
      description: this.state.description,
      coins: this.state.coins,
      yearCreated: this.state.date,
      dateTransaction: this.state.transaction,
      fileContent: combinedFiles,
      fileTypes: combinedFileTypes,
    };

    fetch('/RegisterArt', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.text()) 
    .then(data => {
      // error check
      if (!data.startsWith('Success')) {
        // show error message
        this.setState({
          isUploading: false,
          showUploadModal: false,
          successMsg: '',
          errorMsg: data,
        });
      } else {
        // show success message and clear fields
        this.setState({
          isUploading: false,
          showUploadModal: false,
          fileList: [],
          successMsg: 'Artwork Successfully Registered!',
          errorMsg: '',
          artist: '',
          title: '',
          description: '',
          date: '',
          coins: '',
          transaction: '',
        });
      }
    })
  }

  render() {
    return (
      <CenterPage>
        <FullTable><tbody>
          <tr><td colSpan='3'>
            <br/>
            <PageTitle>Register Art</PageTitle>
            <br/>
          </td></tr>
          <tr>
            <td colSpan='3' style={{paddingLeft:'10px', paddingRight:'10px'}}><hr style={{border: '1px solid gray'}} /></td>
          </tr>
        </tbody></FullTable>
        <FullTable><tbody>
          <tr>
            <td>Artist Name:</td>
            <td><TextBox type='text' name='artist' value={this.state.artist} onChange={this.captureInput} /></td>
          </tr>
          <tr>
            <td>Artwork Title:</td>
            <td><TextBox type='text' name='title' value={this.state.title} onChange={this.captureInput} /></td>
          </tr>
          <tr>
            <td>Artwork Year:</td>
            <td><TextBox type='number' name='date' value={this.state.date} onChange={this.captureInput} /></td>
          </tr>
          <tr>
            <td style={{verticalAlign: 'top'}}>Description:</td>
            <td><TextArea height='120px' type='text' name='description' value={this.state.description} onChange={this.captureInput} /></td>
          </tr>
          <tr>
            <td>MonetPennies Paid:</td>
            <td><TextBox type='number' name='coins' value={this.state.coins} onChange={this.captureInput} /></td>
          </tr>
          <tr>
            <td>Transaction Date:</td>
            <td><TextBox type='date' name='transaction' value={this.state.transaction} onChange={this.captureInput} /></td>
          </tr>
        </tbody></FullTable>
        <br/>
        <FullTable><tbody>
          <tr>
            <CenterTD>
            <CenterDiv>
              <Dropzone
                accept=".jpg, .png, .jpeg, .bmp, .gif"
                maxSizeBytes="25000000" // 25 Mb
                onChangeStatus={this.handleChangeStatus.bind(this)}
                InputComponent={this.inputContent.bind(this)}
                canCancel={false}
                styles={{ 
                  dropzone: { height: '120px', width: '100%', overflow: 'hidden', background: 'white', border: 'dashed 2px blue', borderRadius: '5px' },
                  dropzoneActive: { border: 'dashed 2px #3fd947', background: 'aliceblue' }
                }} 
              />
            </CenterDiv>
            </CenterTD>
          </tr>
          <tr><td>
            {this.fileList()}
          </td></tr>
        </tbody></FullTable>
        <br/>
        <FullTable><tbody><tr>
          <td width="100px" />
          <td>
            <Button variant="contained" sx={{ mx: '5px', mt: '10px', width: '100px' }} size="small" onClick={() => this.props.history.push("/")}>Cancel</Button>
          </td>
          <td width="5px" />
          <td>
            <Button variant="contained" sx={{ mx: '5px', mt: '10px', width: '100px' }} size="small" onClick={() => this.registerArt()}>Save</Button>
          </td>
          <td width="100px" />
        </tr></tbody></FullTable>

        {this.state.errorMsg && (
          <Popup
            type="error"
            title={this.state.errorMsg}
            onClose={() => this.setState({ errorMsg: '' })}
          />
        )}

        {this.state.successMsg && (
          <Popup
            type="success"
            title={this.state.successMsg}
            onClose={() => this.setState({ successMsg: '' })}
          />
        )}

        <InlineDisplay
          title={this.state.selectedFileName}
          show={this.state.showAttachment}
          onClose={() => this.setState({ showAttachment: false })}
          buttonMessage="Close"
          previewSrc={this.state.previewURL}
          fileExt={this.state.fileExt}
        />
      </CenterPage>
    );
  }
}
