import React, { Component } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MonetPenny from '../graphics/MonetPenny.png';

export default class About extends Component {
  render () {
    return (
      <Container>
        <Box sx={{ mx: 'auto' }}>
          <Typography variant="h4" sx={{ fontWeight: 'medium', fontFamily: 'Satisfy', mb: 2, mt: 4, textAlign: 'left' }}>
            <img src={MonetPenny} alt="" width="60px" style={{ marginRight: '20px' }} />
            About MonetPenny
          </Typography>
          <hr/>
          <p>
            MonetPenny is a new cryptocurrency that is planned to launch later in 2022, based off of the &nbsp; 
            <a href="https://solana.com/" target="_blank" rel="noopener noreferrer">Solana Blockchain</a> and technology.
          </p>

          <p>
            Additional information will be made available, once the organization is built out and we have a product available
            for launch.
          </p>
        </Box>
      </Container>
    );
  }
}
