import { Base64 } from 'js-base64';

export const encodeText = (textToEncode) => {
  let encoded = Base64.encode(textToEncode);
  return encoded.substr(encoded.length/2) + encoded.substr(0, encoded.length/2);
}

export const decodeText = (textToDecode) => {
  try {
    var unscrambled = textToDecode.substr(textToDecode.length/2) + textToDecode.substr(0, textToDecode.length/2)
    return Base64.decode(unscrambled);
  }
  catch {
    return 'Error: Invalid Base64 String';
  }
}

export const fileDataToUrl = (uintarr)=>  {
  var binary = atob(uintarr);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  var file = new Blob([new Uint8Array(array)]);
  const url = URL.createObjectURL(file);
  return url;
}