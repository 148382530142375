import React, { useReducer, createContext } from 'react';
import _ from 'lodash';

const Session = createContext();
const authenticatedUser = localStorage.getItem('user');

const initialState = {
  user: !_.isEmpty(authenticatedUser) ? JSON.parse(authenticatedUser) : null,
  loading: false,
};

export const checkRouteForString = (term, pathname) => pathname.includes(term);

export const ACTIONS = {
  REGISTERING_USER: 'REGISTERING_USER',
  SET_USER: 'SET_USER',
  UPDATE_USER: 'UPDATE_USER',
  REMOVE_USER: 'REMOVE_USER',
};

export const JWT_SECRET_KEY = 'john832';

const reducer = (state = initialState, { type, value }) => {
  switch (type) {
    case ACTIONS.REGISTERING_USER:
      return { ...state, loading: true };
    case ACTIONS.FAILED_REGISTRATION:
      return { ...state, loading: false };
    case ACTIONS.SET_USER:
      return { ...state, user: value, loading: false };
    case ACTIONS.UPDATE_USER:
      return { ...state, user: value };
    case ACTIONS.REMOVE_USER:
      return { ...state, user: null };

    default:
      return initialState;
  }
};

function SessionProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Session.Provider value={value}>{props.children}</Session.Provider>;
}

const SessionConsumer = Session.Consumer;

export { Session, SessionProvider, SessionConsumer };
