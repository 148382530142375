import React, { Component } from 'react';
import axios from 'axios';
import { CenterPage, FullTable, PageTitle, TextBox, Button, SearchText, LeftTD, RightTD } from '../helpers/LayoutComponents';
import MenuIcon from '@mui/icons-material/Menu';
import Alert from '@mui/material/Alert';
import styled from 'styled-components';
import { CornerMenu } from '../helpers/LayoutComponents';

const LinkTR = styled.tr`
  cursor: pointer;
  borderTop: 1px solid gray;
  &: hover {
    background-color: #333333;
  }
  &: active {
    background-color: #777777;
  }
`;

export default class SearchArt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      artworkID: 0, 
      images: [],
      searchResults: [],
      searchClicked: false,
      isSearching: false
    };
  }

  captureInput = e => {
    this.setState({[e.target.name]: e.target.value});
  }

  detectEnterKey = e => {
    if(e.charCode === 13)
      this.onSearchClicked();
  }

  async onSearchClicked() {
    if(!this.state.titleSearch && !this.state.artistSearch && !this.state.descriptionSearch &&
      !this.state.createdAfter && !this.state.createdBefore && !this.state.transactionAfter &&
      !this.state.transactionBefore && !this.state.minCoins && !this.state.maxCoins) {
      this.setState({errorMsg: 'No Search Parameters Specified!', searchClicked: false});
      return;
    }

    this.setState({infoMsg: "Searching - Please Wait!", isSearching: true});

    var searchParams = (<code> 
      {this.state.titleSearch ? "Title Contains: (" + this.state.titleSearch + ") " : ""}
      {this.state.artistSearch ? "Artist Contains: (" + this.state.artistSearch + ") " : ""}
      {this.state.descriptionSearch ? "Description Contains: (" + this.state.descriptionSearch + ") " : ""}
      {this.state.createdAfter ? "Created After: (" + this.state.createdAfter + ") " : ""}
      {this.state.createdBefore ? "Created Before: (" + this.state.createdBefore + ") " : ""}
      {this.state.transactionAfter ? "Purchased After: (" + this.state.transactionAfter + ") " : ""}
      {this.state.transactionBefore ? "Purchased Before: (" + this.state.transactionBefore + ") " : ""}
      {this.state.minCoins ? "Price Min: (" + this.state.minCoins + ") " : ""}
      {this.state.maxCoins ? "Price Max: (" + this.state.maxCoins + ") " : ""}
      <br/>
    </code>);

    // todo: call server, pass in parameters,
    var response = axios.get('/getgallerysearch', { 
      params: { 
        pTitle: this.state.titleSearch, 
        pArtist: this.state.artistSearch, 
        pDescription: this.state.descriptionSearch,
        pCreatedAfter: this.state.createdAfter,
        pCreatedBefore: this.state.createdBefore,
        pTransactionAfter: this.state.transactionAfter,
        pTransactionBefore: this.state.transactionBefore, 
        pMinCoins: this.state.minCoins,
        pMaxCoins: this.state.maxCoins
      } 
    });
    try {
      var resultData = (await response).data;
      this.setState({ 
        errorMsg: '', 
        infoMsg: '', 
        searchResults: resultData, 
        isSearching: false, 
        searchClicked: true, 
        searchParams: searchParams });
    } 
    catch {
      this.setState({ 
        errorMsg: 'An Error Occurred While Searching!', 
        infoMsg: '', 
        isSearching: false, 
        searchResults: [], 
        searchClicked: true, 
        searchParams: searchParams });
    }
  }

  showArtwork(artworkID) {
    this.props.history.push("/gallery/" + artworkID);
  }

  getSearchResultsTable() {
    // search params
    var searchParams = this.state.searchParams ? this.state.searchParams : (<></>);

    // if we have not tried to search yet, don't display results
    if(!this.state.searchClicked) {
      return (<></>);
    }

    if(!this.state.searchResults || this.state.searchResults.length === 0) {
      return (
        <>
          {searchParams}
          <br/>
          No Results Found For Search Parameters Specified!
        </>
      );
    }
    else {
      return (
        <>
          {searchParams}
          <br/>
          <SearchText>
          <FullTable><tbody>
            <tr style={{border: '1px solid gray'}}>
              <td style={{paddingRight: '5px'}}>Title</td>
              <td style={{paddingRight: '5px'}}>Artist</td>
              <td style={{paddingRight: '5px'}}>Created</td>
              <td style={{paddingRight: '5px'}}>Purchased</td>
              <td style={{paddingRight: '5px'}}>Price</td>
              <td>Description</td>
            </tr>
            {this.state.searchResults.map(artwork => (
              <LinkTR key={artwork.artworkID} onClick={()=> this.showArtwork(artwork.artworkID)}>
                <td>{artwork.title}</td>
                <td>{artwork.artist}</td>
                <td>{artwork.yearCreated}</td>
                <td>{artwork.dateTransaction}</td>
                <td>{artwork.coins}</td>
                <td>{artwork.description}</td>
              </LinkTR>
            ))}
          </tbody></FullTable>
          </SearchText>
        </>
      );
    }
  }

  render() {
    return (
      <CenterPage>
        <CornerMenu>
          <MenuIcon style={{fontSize: '40px'}} onClick={()=> this.showMenu()} />
        </CornerMenu>
        <FullTable><tbody>
          <tr><td colSpan='3'>
            <br/>
            <PageTitle>Search Art</PageTitle>
            <br/>
          </td></tr>
          <tr>
            <td colSpan='3' style={{paddingLeft:'10px', paddingRight:'10px'}}><hr style={{border: '1px solid gray'}} /></td>
          </tr>
        </tbody></FullTable>
        <FullTable><tbody>
          <tr>
            <RightTD width='160px'><SearchText>Title:</SearchText></RightTD>
            <LeftTD colSpan={4}><TextBox type='text' width='314px' name='titleSearch' value={this.state.titleSearch || ''} onChange={this.captureInput} onKeyPress={this.detectEnterKey} /></LeftTD>
          </tr>
          <tr>
            <RightTD><SearchText>Artist:</SearchText></RightTD>
            <LeftTD colSpan={4}><TextBox type='text' width='314px' name='artistSearch' value={this.state.artistSearch || ''} onChange={this.captureInput} onKeyPress={this.detectEnterKey} /></LeftTD>
          </tr>
          <tr>
            <RightTD><SearchText>Description:</SearchText></RightTD>
            <LeftTD colSpan={4}><TextBox type='text' width='314px' name='descriptionSearch' value={this.state.descriptionSearch || ''} onChange={this.captureInput} onKeyPress={this.detectEnterKey} /></LeftTD>
          </tr>

          <tr>
            <RightTD><SearchText>Artwork Year:</SearchText></RightTD>
            <LeftTD>
              <TextBox type='number' width='140px' name='createdAfter' value={this.state.createdAfter || ''} onChange={this.captureInput} onKeyPress={this.detectEnterKey} />
            </LeftTD>
            <RightTD><SearchText style={{marginLeft: '5px'}}>to:</SearchText></RightTD>
            <RightTD>
              <TextBox type='number' width='140px' name='createdBefore' value={this.state.createdBefore || ''} onChange={this.captureInput} onKeyPress={this.detectEnterKey} /> 
            </RightTD>
            <td width='30px' />
          </tr>

          <tr>
            <RightTD><SearchText>Artwork Purchased:</SearchText></RightTD>
            <LeftTD>
              <TextBox type='date' width='140px' name='transactionAfter' value={this.state.transactionAfter || ''} onChange={this.captureInput} onKeyPress={this.detectEnterKey} /> 
            </LeftTD>
            <RightTD><SearchText style={{marginLeft: '5px'}}>to:</SearchText></RightTD>
            <RightTD>
              <TextBox type='date' width='140px' name='transactionBefore' value={this.state.transactionBefore || ''} onChange={this.captureInput} onKeyPress={this.detectEnterKey} /> 
            </RightTD>
            <td width='30px' />
          </tr>

          <tr>
            <RightTD><SearchText>Price (MPNY):</SearchText></RightTD>
            <LeftTD>
              <TextBox type='number' width='140px' name='minCoins' value={this.state.minCoins || ''} onChange={this.captureInput} onKeyPress={this.detectEnterKey} /> 
            </LeftTD>
            <RightTD><SearchText style={{marginLeft: '5px'}}>to:</SearchText></RightTD>
            <RightTD>
              <TextBox type='number' width='140px' name='maxCoins' value={this.state.maxCoins || ''} onChange={this.captureInput} onKeyPress={this.detectEnterKey} />
            </RightTD>
            <td width='30px' />
          </tr>
        </tbody></FullTable>

        <Button style={{width: '80px', marginRight: '5px', marginTop: '15px'}} onClick={() => this.props.history.push('/gallery/random')}>Gallery</Button>
        <Button style={{width: '80px', marginLeft: '5px', marginTop: '15px'}} onClick={() => this.onSearchClicked()} disabled={this.state.isSearching}>Search</Button>
        <hr/>
        {this.getSearchResultsTable()}
        
        
        {this.state.errorMsg && <Alert severity="error" sx={{ my: 2 }} onClose={() => this.setState({ errorMsg: '' })} >{this.state.errorMsg}</Alert>}
        {this.state.infoMsg && <Alert severity="info" sx={{ my: 2 }} onClose={() => this.setState({ infoMsg: '' })} >{this.state.infoMsg}</Alert>}
      </CenterPage>
    );
  }
}
