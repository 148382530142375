import React from 'react';
import { PopupClose, PopupContent, PopupWrapper } from './PopupInlineElements';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import WarningIcon from '@material-ui/icons/Warning';
import CancelIcon from '@material-ui/icons/Cancel';

export default function Popup(props) {
  const icons = {
    success: <ImportantDevicesIcon size="2rem" style={{ color: "#37426e" }} />,
    error: <WarningIcon size="2rem" style={{ color: "#bf1a0b" }} />,
  };
  return (
    <PopupWrapper>
      {icons[props.type]}
      <PopupContent>{props.title}</PopupContent>
      <PopupClose onClick={props.onClose}>
        <CancelIcon size="2rem" />
      </PopupClose>
    </PopupWrapper>
  );
}
