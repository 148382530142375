import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import LoginState from '../helpers/LoginState';

const ModalWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 1rem;
    background-color: rgba(100, 100, 100, 0.6);
    z-index: 9998;
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
`;

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  padding: 3rem;
  background-color: rgba(30, 30, 30, 0.8);
  border: 1px solid #352525;
  z-index: 9999;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ModalLink = styled.td`
  font-size: 32px;
  cursor: pointer;
  text-align: center;
  align-items: center;
  color: white;
  padding-top: 10px;
  &: hover {
    color: yellow;
    text-decoration: underline;
  }
  &: active {
    color: white;
    text-decoration: underline;
  }
`;

const CloseLink = styled.td`
  cursor: pointer;
  text-align: center;
  align-items: center;
  color: red;
  padding-bottom: 20px;
  &: hover {
    color: #fc4e03;
  }
  &: active {
    color: red;
  }
`;

function logout() {
  LoginState.loggedIn = false;
  LoginState.userName = '';
  localStorage.removeItem('user');
  localStorage.removeItem('token');
}

export function ModalMenu ({ navigateTo }) {
  return (
    // later check for whether logged in and display different menu. depending on page we're on, also change link
    <ModalWrapper>
      <ModalContent>
        <table><tbody>
        <tr><CloseLink onClick={() => navigateTo("close")}><CloseIcon style={{fontSize: '48px'}} /></CloseLink></tr>
          <tr><ModalLink onClick={() => navigateTo("/#home")}>Home</ModalLink></tr>
          <tr><ModalLink onClick={() => navigateTo("/#about")}>About</ModalLink></tr>
          <tr><ModalLink onClick={() => navigateTo("/#resources")}>Resources</ModalLink></tr>
          <tr><td><hr style={{backgroundColor: 'white'}} /></td></tr>
          {LoginState.loggedIn ? (
            <>
              <tr><ModalLink onClick={() => navigateTo('/gallery/random')}>Art Gallery</ModalLink></tr>
              <tr><ModalLink onClick={() => navigateTo('/search')}>Search Art</ModalLink></tr>
              <tr><td><hr style={{backgroundColor: 'white'}} /></td></tr>
              <tr><ModalLink onClick={() => navigateTo('/wallet')}>Wallet</ModalLink></tr>
              <tr><ModalLink onClick={() => {logout(); navigateTo('/login');}}>Logout</ModalLink></tr>
            </>
          ) : (
            <tr><ModalLink onClick={() => navigateTo("/login")}>Login/Register</ModalLink></tr>
          )}
        </tbody></table>
      </ModalContent>
    </ModalWrapper>
  );
}