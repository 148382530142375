import React, { Component } from 'react';
import { CenterPage, FullTable, PageTitle, MidHeader, CenterTD } from '../helpers/LayoutComponents';
import LoginState from '../helpers/LoginState';
import QRCode from 'qrcode';

export default class ReceiveCoins extends Component {
  constructor(props) {
    super(props);
    this.state = { qrCode: '' };
    this.generateData();
  }

  async generateData() {
    try {
      var url = await QRCode.toDataURL(LoginState.userName);
      this.setState({ qrCode: url });
    }
    catch {}
  }

  render () {
    return (
      <CenterPage>
        <FullTable><tbody>
          <tr><td>
            <br/>
            <PageTitle>Receive Coins</PageTitle>
            <br/>
          </td></tr>
          <tr>
            <CenterTD>
              <MidHeader>@{LoginState.userName}</MidHeader>
            </CenterTD>
          </tr>
          <tr height='20px' />
          <tr>
            <CenterTD>
              {this.state.qrCode && (
                <img alt='Send To Address' src={this.state.qrCode} 
                  style={{ border: '1px solid gray' }}
                  height='300px' width='300px' />
              )}
            </CenterTD>
          </tr>
          <tr height='20px' />
          <tr>
            <CenterTD style={{ textAlign: 'center' }}>
              <div style={{ maxWidth:'350px', margin: '0 auto' }}>
              <MidHeader style={{padding: '10px'}}>
                Show the sender your <span style={{color:'yellow'}}> User Name </span> or let 
                them <span style={{color:'yellow'}}> Scan the QR Code </span> above to have them 
                to send coins to your account.
              </MidHeader>
              </div>
            </CenterTD>
          </tr>
        </tbody></FullTable>
      </CenterPage>
    );
  }
}
