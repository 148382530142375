import React, { Component } from 'react';
import { FullTable, CenterPage, SmallLink, SearchText, ActivityRow, TableHeader, LeftTD, RightTD, Error, CenterTD } from '../helpers/LayoutComponents';
import axios from 'axios';
import LoginState from '../helpers/LoginState';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';

export default class ActivityPrint extends Component {

  constructor(props) {
    super(props);
    // todo: parse this.props.params.search to fill out below:
    var paramSplit = this.props.match.params.search.split(',');

    this.state = {
      category: paramSplit[0].split(':')[1],
      minAmount: paramSplit[1].split(':')[1],
      maxAmount: paramSplit[2].split(':')[1],
      minDate: paramSplit[3].split(':')[1],
      maxDate: paramSplit[4].split(':')[1],
      keywords: paramSplit[5].split(':')[1].replace('!comma!', ',').replace('!colon!', ':'),
      searchResults: [],
      transactionOpen: []
    }
    this.handleSearch();
  }

  async handleSearch() {
    // todo: validate search params
    /* var floatAmt = parseFloat(this.state.amount);
    if(this.state.amount.length === 0 || this.state.amount.includes('e') || isNaN(floatAmt)) {
      this.setState({errorMsg: 'Please Enter a Valid Number of ArtCoins To Send!', successMsg: ''});
      return;
    } */

    // if validation has passed so far, we will try sending it over to the server
    const response = axios.get('../searchfilter', {
      params: {
        pUserName: LoginState.userName,
        pKeywords: this.state.keywords,
        pCategory: this.state.category,
        pMinAmount: this.state.minAmount,
        pMaxAmount: this.state.maxAmount,
        pMinDate: this.state.minDate,
        pMaxDate: this.state.maxDate,
        pPage: 1
      }
    });
    const resultMessage = (await response).data;
    
    // respond after update
    if(resultMessage !== "Error") {
      this.setState({
        // x = resultMessage
        searchDisplay: false,
        searchResults: resultMessage.results,
        errorMsg: ''
      });
    }
    else {
      this.setState({
        errorMsg: 'An Error Occurred While Retrieving Data!'
      })
    }
  }

  openTransaction(index) {
    var to = this.state.transactionOpen;
    to[index] = to[index] ? false : true;
    this.setState({ transactionOpen: to });
  }

  render () {
    return (
      <CenterPage>
        <FullTable><tbody>
          <tr>
            <RightTD>
              <SmallLink onClick={() => window.close()}>
                <CloseIcon style={{ fontSize: 20, paddingLeft: '3px', paddingRight: '3px' }} />Close
              </SmallLink> 
              <SmallLink onClick={() => window.print()}>
                <PrintIcon style={{ fontSize: 20, paddingLeft: '3px', paddingRight: '3px' }} />Print
              </SmallLink>
            </RightTD>
          </tr>
          <tr>
            <CenterTD>
              <table width="100%"><tbody>
                {(this.state.keywords || this.state.category > 0 || this.state.minAmount || this.state.maxAmount ||
                  this.state.minDate || this.state.maxDate) && (
                  <tr>
                    <LeftTD colSpan='7'>
                      {this.state.keywords && (
                        <SearchText>Keywords: ({this.state.keywords})<br/></SearchText>
                      )}
                      {this.state.category > 0 && (
                        <SearchText>Category: (
                          {this.state.category === '1' && ('Purchase Coins')}
                          {this.state.category === '2' && ('Sent Coins')}
                          {this.state.category === '3' && ('Received Coins')}
                        )<br/></SearchText>
                      )}
                      {this.state.minAmount && (
                        <SearchText>Amount {'>='} {this.state.minAmount}{!this.state.maxAmount && (<br/>)}</SearchText>
                      )}
                      {this.state.maxAmount && (
                        <SearchText>Amount {'<='} {this.state.maxAmount}<br/></SearchText>
                      )}
                      {this.state.minDate && (
                        <SearchText>Date {'>='} {this.state.minDate}</SearchText>
                      )}
                      {this.state.maxDate && (
                        <SearchText>Date {'<='} {this.state.maxDate}</SearchText>
                      )}
                    </LeftTD>
                  </tr>
                )}
                <tr height='10px' />
                {this.state.searchResults.length > 0 ? (
                  <>
                    <ActivityRow height='35px' background='#999999' color='white'>
                      <td width='10px' />
                      <LeftTD><TableHeader>Transaction</TableHeader></LeftTD>
                      <td></td>
                      <RightTD><TableHeader>Amount</TableHeader></RightTD>
                      <RightTD><TableHeader>Balance</TableHeader></RightTD>
                      <td></td>
                      <td width='10px' />
                    </ActivityRow>
                    {this.state.searchResults.length > 0 &&
                      this.state.searchResults.map((activity) => (
                      <ActivityRow>
                        <td width='10px' />
                        <LeftTD>
                          <b>{activity.description}</b>
                          <br/>
                          {activity.timestamp}
                          {this.state.transactionOpen[activity.transactionID] && (
                            <>
                              <br/>
                              <table><tbody>
                                <tr height='5px' />
                                <tr>
                                  <td width='35px'>From: </td>
                                  <td>{activity.fromUser}</td>
                                </tr>
                                <tr>
                                  <td>To: </td>
                                  <td>{activity.toUser}</td>
                                </tr>
                                <tr>
                                  <td>Fee: </td>
                                  <td>{activity.fee}</td>
                                </tr>
                                <tr height='5px' />
                              </tbody></table>
                            </>
                          )}
                        </LeftTD>
                        <td></td>
                        <RightTD>
                          {activity.amount[0] !== '-' && '+'}{activity.amount} 
                          {activity.fee !== '0.000' && (<br/> + '-' + activity.fee)}
                        </RightTD>
                        <RightTD>{activity.balance}</RightTD>
                        {this.state.transactionOpen[activity.transactionID] ? (
                          <RightTD width='30px'>
                            <RemoveCircleOutlineIcon 
                              style={{ fontSize: 20, color: '#999999', cursor: 'pointer', paddingLeft: '3px' }} 
                              onClick={ ()=> this.openTransaction(activity.transactionID) } />
                          </RightTD>
                        ) : (
                          <RightTD width='30px'>
                            <AddCircleOutlineIcon 
                              style={{ fontSize: 20, color: '#999999', cursor: 'pointer', paddingLeft: '3px' }} 
                              onClick={ ()=> this.openTransaction(activity.transactionID) } />
                          </RightTD>
                        )}
                        <td width='10px' />
                      </ActivityRow>
                    ))}
                  </>
                ) : (
                  <>
                    <tr><td><hr/></td></tr>
                    <tr><td>No Results Found!</td></tr>
                  </>
                )}
              </tbody></table>
            </CenterTD>
          </tr>
          <tr>
            <td><Error>{this.state.errorMsg}</Error></td>
          </tr>
        </tbody></FullTable>
      </CenterPage>
    );
  }
}
