import React, { Component } from 'react';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import platform from 'platform';
import Typography from '@mui/material/Typography';
import { CenterPage, FullTable, TextBox, Button, Link, LeftTD, RightTD, CenterTD } from '../helpers/LayoutComponents';
import { encodeText } from '../helpers/utils';
import { JWT_SECRET_KEY } from '../helpers/SessionHelper';
import LoginState from '../helpers/LoginState';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import WoodGradient from '../graphics/WoodGradient.jpg';

export default class Login extends Component {

  constructor(props) {
    super(props);

    // if we are already logged in, redirect
    if(LoginState.loggedIn) {
      this.props.history.push('/');
    }

    const browserInfo = platform.os.family + " " + platform.os.version + " (" +
      platform.name + " Browser)";

    this.state = { 
      browserInfo: browserInfo,
      userName: '',
      password: ''
    }
  }

  captureInput = e => { this.setState({[e.target.name]: e.target.value}); };

  async attemptLogin() {
    this.setState({errorMsg: '', infoMsg: 'Please Wait!'});
    const response = axios.get('../loginuser', {
      params: {
        pField: this.state.userName,
        pPassword: encodeText(this.state.password),
        pBrowser: this.state.browserInfo
      },
    });
    const resultMessage = ((await response).data).toString();
    if (resultMessage.startsWith('Success:')) {
      // logged in successfully
      this.setState({errorMsg: '', infoMsg: '', userName: '', password: ''});

      // handle login state code.
      LoginState.loggedIn = true;
      LoginState.userName = resultMessage.substr(9);
      const token = jwt.sign({ LoginState }, JWT_SECRET_KEY, { algorithm: 'HS256' });
      localStorage.setItem('user', JSON.stringify(LoginState));
      localStorage.setItem('token', token);
      localStorage.setItem('lastTimeStamp', Date.now());
      this.props.history.push('/wallet');
    } 
    else {
      this.setState({errorMsg: resultMessage, infoMsg: ''});
    }
  }

  loginKeyPressed = e => {
    if(e.charCode === 13)
      this.attemptLogin();
  }

  render() {
    return (
      <CenterPage>
        <FullTable><tbody>
          <tr><td colSpan='3'>
            <Typography variant="h4" sx={{ fontWeight: 'medium', fontFamily: 'Satisfy', mb: 2, mt: 4, textAlign: 'center' }}>Wallet Login</Typography>
          </td></tr>
          <tr><td>
            <Box sx={{ maxWidth: '300px', mx: 'auto', mt:2, border: 1, borderRadius: 2, 
              backgroundImage: `url(${WoodGradient})`, color: 'white', borderColor: '#555555', p: 2 }}>
              <table width="100%"><tbody>
                <tr><CenterTD>
                  <TextBox
                    width="250px"
                    placeholder='User Name / Email'
                    name='userName'
                    value={this.state.userName}
                    onKeyPress={this.loginKeyPressed}
                    onChange={this.captureInput}
                  />
                </CenterTD></tr>
                <tr><CenterTD>
                  <TextBox
                    width="250px"
                    placeholder='Password'
                    name='password'
                    type='password'
                    value={this.state.password}
                    onKeyPress={this.loginKeyPressed}
                    onChange={this.captureInput}
                  />
                </CenterTD></tr>
                <tr><CenterTD>
                  <Button disabled={this.state.infoMsg}
                    onClick={() => this.attemptLogin()}>Log In</Button>
                </CenterTD></tr>
                <tr><CenterTD>
                  <div>
                  <table style={{ width: '100%', textAlign: 'center' }}><tbody><tr>
                    <LeftTD style={{ paddingLeft: '10px', paddingRight: '10px' }}><Link onClick={() => this.props.history.push('/forgotpw')}>Forgot Password</Link></LeftTD>
                    <RightTD style={{ paddingLeft: '10px', paddingRight: '10px' }}><Link onClick={() => this.props.history.push('/registeruser')}>Register New User</Link></RightTD>
                  </tr></tbody></table>
                  </div>
                </CenterTD></tr>
              </tbody></table>
              {this.state.infoMsg && <Alert severity="info" sx={{ my: 1, paddingTop: '0px', paddingBottom: '0px', fontSize: '13px' }} 
                variant="filled">{this.state.infoMsg}</Alert>}
              {this.state.errorMsg && <Alert severity="error" sx={{ my: 1, paddingTop: '0px', paddingBottom: '0px', fontSize: '13px' }} 
                variant="filled" onClose={() => this.setState({ errorMsg: '' })}>{this.state.errorMsg}</Alert>}
            </Box>
          </td></tr>
        </tbody></FullTable>
      </CenterPage>
    );
  }
}
