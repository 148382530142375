import React, { Component } from 'react';
import axios from 'axios';
import platform from 'platform';
import Typography from '@mui/material/Typography';
import { CenterPage, FullTable, TextBox, Button } from '../helpers/LayoutComponents';
import { encodeText } from '../helpers/utils';
import LoginState from '../helpers/LoginState';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import WoodGradient from '../graphics/WoodGradient.jpg';

export default class RegisterUser extends Component {

  constructor(props) {
    super(props);

    // if we are already logged in, redirect
    if(LoginState.loggedIn) {
      this.props.history.push('/');
    }

    const browserInfo = platform.os.family + " " + platform.os.version + " (" +
      platform.name + " Browser)";

    this.state = { 
      accessingServer: false,
      browserInfo: browserInfo,
      email: '',
      userName: '',
      password: '',
      validEmail: false,
      validUser: false,
      validPass: false
    }
  }

  async validateEmail(email) {
    const emailValidate = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validEmail = emailValidate.test(String(email));
    if(!validEmail) {
      this.setState({ validEmail: false, errorMsg: "Invalid Email Format!" });
      return;
    }

    // check for existing account
    const response = axios.get('../checkemail', {
      params: { pEmail: email }
    });
    const resultMessage = ((await response).data).toString();
    if(resultMessage)
      this.setState({ validEmail: false, errorMsg: "Account With Specified Email Already Exists!" });
    else
      this.setState({ validEmail: true, errorMsg: "" });
  }

  async validateUser(user) {
    if(user.length < 5) {
      this.setState({ validUser: false, errorMsg: "UserName Too Short!" });
      return;
    }

    // also check for non alphanumeric characters
    const alphanumericValidate = /^[a-z0-9]+$/i;
    if(!alphanumericValidate.test(String(user))) {
      this.setState({ validUser: false, errorMsg: "Invalid UserName (Letters and Numbers Only)!" });
      return;
    }

    // check for existing account
    const response = axios.get('../checkusername', {
      params: { pUserName: user }
    });
    const resultMessage = ((await response).data).toString();
    if(resultMessage)
      this.setState({ validUser: false, errorMsg: "UserName Already Taken!" });
    else
      this.setState({ validUser: true, errorMsg: "" });
  }

  validatePass(pass) {
    if(pass.length < 8) {
      this.setState({ validPass: false, errorMsg: "Password Too Short!" });
      return;
    }

    // todo: optional - check for more complex password
    this.setState({ validPass: true, errorMsg: "" });
  }

  captureInput = e => { 
    this.setState({[e.target.name]: e.target.value}); 
    if(e.target.name === "email" && e.target.value.length > 6) {
      this.validateEmail(e.target.value);
    }
    if(e.target.name === "userName" && e.target.value.length > 0) {
      this.validateUser(e.target.value);
    }
    if(e.target.name === "password" && e.target.value.length > 0) {
      this.validatePass(e.target.value);
    }
  };

  async createUser() {
    this.setState({ accessingServer: true, successMsg: "", errorMsg: "", infoMsg: "Please Wait!" });
    const response = axios.get('../createnewuser', {
      params: {
        pEmail: this.state.email,
        pUserName: this.state.userName,
        pPassword: encodeText(this.state.password),
        pBrowser: this.state.browser
      },
    });
    const resultMessage = ((await response).data).toString();
    if (resultMessage.startsWith('Success')) {
      this.setState({ successMsg: "User Successfully Registered! Please Check Your Email!", errorMsg: "", infoMsg: "" })
    } 
    else {
      this.setState({ accessingServer: false, successMsg: "", errorMsg: resultMessage, infoMsg: ""});
    }
  }

  enterKeyPressed = e => {
    if(e.charCode === 13 && this.state.validEmail && this.state.validUser && this.state.validPass)
      this.createUser();
  }

  render() {
    return (
      <CenterPage>
        <FullTable><tbody>
          <tr><td colSpan='3'>
            <Typography variant="h4" sx={{ fontWeight: 'medium', fontFamily: 'Satisfy', mb: 2, mt: 4, textAlign: 'center' }}>Create Wallet</Typography>
          </td></tr>
          <tr><td>
            <Box sx={{ maxWidth: '300px', mx: 'auto', mt:2, border: 1, borderRadius: 2, 
              backgroundImage: `url(${WoodGradient})`, color: 'white', borderColor: '#555555', p: 2 }}>
              <table width="100%"><tbody>
                <tr><td width='35px' /><td>
                  <TextBox
                    placeholder='Email Address'
                    name='email'
                    value={this.state.email}
                    onKeyPress={this.enterKeyPressed}
                    onChange={this.captureInput}
                  />
                </td><td width='35px'>
                  {this.state.email.length < 7 ? (
                    <InfoIcon fontSize="medium" style={{ color:'darkgray' }} />
                  ):(<>
                    {this.state.validEmail ? (
                      <CheckCircleIcon fontSize="medium" style={{ color:'green' }} />
                    ) : (
                      <CancelIcon fontSize="medium" style={{ color:'red' }} />
                    )}
                  </>)}
                </td></tr>
                <tr><td width='35px' /><td>
                  <TextBox
                    placeholder='User Name'
                    name='userName'
                    value={this.state.userName}
                    onKeyPress={this.enterKeyPressed}
                    onChange={this.captureInput}
                  />
                </td><td width='35px'>
                  {this.state.userName.length < 1 ? (
                    <InfoIcon fontSize="medium" style={{ color:'darkgray' }} />
                  ):(<>
                    {this.state.validUser ? (
                      <CheckCircleIcon fontSize="medium" style={{ color:'green' }} />
                    ) : (
                      <CancelIcon fontSize="medium" style={{ color:'red' }} />
                    )}
                  </>)}
                </td></tr>
                <tr><td width='35px' /><td>
                  <TextBox
                    placeholder='Password'
                    name='password'
                    type='password'
                    value={this.state.password}
                    onKeyPress={this.enterKeyPressed}
                    onChange={this.captureInput}
                  />
                </td><td width='35px'>
                  {this.state.password.length < 1 ? (
                    <InfoIcon fontSize="medium" style={{ color:'darkgray' }} />
                  ):(<>
                    {this.state.validPass ? (
                      <CheckCircleIcon fontSize="medium" style={{ color:'green' }} />
                    ) : (
                      <CancelIcon fontSize="medium" style={{ color:'red' }} />
                    )}
                  </>)}
                </td></tr>
                <tr><td colSpan="3">
                  <Button width="95px" style={{ marginRight: '3px' }} disabled={this.state.infoMsg} 
                    onClick={() => this.props.history.push("/login")}>Cancel/Back</Button>
                  <Button width="95px" style={{ marginLeft: '3px' }} disabled={!this.state.validEmail || !this.state.validUser || !this.state.validPass} 
                    onClick={() => this.createUser()}>Register User</Button>
                </td></tr>
              </tbody></table>
              {this.state.successMsg && <Alert severity="success" sx={{ my: 1, paddingTop: '0px', paddingBottom: '0px', fontSize: '13px' }} 
                variant="filled" onClose={() => this.setState({ successMsg: '' })}>{this.state.successMsg}</Alert>}
              {this.state.infoMsg && <Alert severity="info" sx={{ my: 1, paddingTop: '0px', paddingBottom: '0px', fontSize: '13px' }} 
                variant="filled" onClose={() => this.setState({ infoMsg: '' })} >{this.state.infoMsg}</Alert>}
              {this.state.errorMsg && <Alert severity="error" sx={{ my: 1, paddingTop: '0px', paddingBottom: '0px', fontSize: '13px' }} 
                variant="filled" onClose={() => this.setState({ errorMsg: '' })} >{this.state.errorMsg}</Alert>}
            </Box>
          </td></tr>
        </tbody></FullTable>
      </CenterPage>
    );
  }
}
