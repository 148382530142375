import React, { Component } from 'react';
import axios from 'axios';
import platform from 'platform';
import Typography from '@mui/material/Typography';
import { CenterPage, FullTable, TextBox, Button, CenterTD } from '../helpers/LayoutComponents';
import LoginState from '../helpers/LoginState';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import WoodGradient from '../graphics/WoodGradient.jpg';

export default class ForgotPW extends Component {

  constructor(props) {
    super(props);

    // if we are already logged in, redirect
    if(LoginState.loggedIn) {
      this.props.history.push('/');
    }

    const browserInfo = platform.os.family + " " + platform.os.version + " (" +
      platform.name + " Browser)";

    this.state = { 
      accessingServer: false,
      browserInfo: browserInfo,
      lostPwEmail: ''
    }
  }

  captureInput = e => { this.setState({[e.target.name]: e.target.value}); };

  async checkAndSendPasswordEmail() {
    // validate email address
    let errorMessages = "";
    const emailValidate = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!emailValidate.test(String(this.state.lostPwEmail).toLowerCase())) {
      errorMessages += "Valid Email Address Not Provided\r\n";
    }

    // if any errors, show message and exit
    if(errorMessages) {
      this.setState({errorMsg: errorMessages, successMsg: ''});
      return;
    }

    this.setState({accessingServer: true, errorMsg: '', successMsg: ""});

    // if we passed validation, try and submit
    const response = axios.get('../retrievepw', { 
      params: { 
        pEmail: this.state.lostPwEmail,
        pBrowser: this.state.browserInfo
      } 
    });
    const resultMessage = ((await response).data).toString();
    if(resultMessage === 'Success') {
      // show success message and clear fields
      this.setState({successMsg: 'The Password Was Sent to The Specified Email!', errorMsg: '', lostPwEmail: '', accessingServer: false});
    }
    else {
      this.setState({errorMsg: resultMessage, successMsg: '', accessingServer: false});
    }
  }

  retrievePressed = e => {
    if(e.charCode === 13)
      this.checkAndSendPasswordEmail();
  }

  render() {
    return (
      <CenterPage>
        <FullTable><tbody>
          <tr><td colSpan='3'>
            <Typography variant="h4" sx={{ fontWeight: 'medium', fontFamily: 'Satisfy', mb: 2, mt: 4, textAlign: 'center' }}>Forgot Password</Typography>
          </td></tr>
          <tr><td>
            <Box sx={{ maxWidth: '300px', mx: 'auto', mt:2, border: 1, borderRadius: 2, 
              backgroundImage: `url(${WoodGradient})`, color: 'white', borderColor: '#555555', p: 2 }}>
              <table width="100%"><tbody>
                <tr><CenterTD>
                  <TextBox
                    width="250px"
                    placeholder='Email Address for Account'
                    name='lostPwEmail'
                    value={this.state.lostPwEmail}
                    onKeyPress={this.retrievePressed}
                    onChange={this.captureInput}
                  />
                </CenterTD></tr>
                <tr><CenterTD>
                  <Button width="90px" style={{ marginRight: '3px' }} disabled={this.state.accessingServer} 
                    onClick={() => this.props.history.push("/login")}>Cancel/Back</Button>
                  <Button width="90px" style={{ marginLeft: '3px' }} disabled={this.state.accessingServer} 
                    onClick={() => this.checkAndSendPasswordEmail()}>Retreive PW</Button>
                </CenterTD></tr>
              </tbody></table>
              {this.state.successMsg && <Alert severity="success" sx={{ my: 1, paddingTop: '0px', paddingBottom: '0px', fontSize: '13px' }} 
                variant="filled" onClose={() => this.setState({ successMsg: '' })}>{this.state.successMsg}</Alert>}
              {this.state.accessingServer && <Alert severity="info" sx={{ my: 1, paddingTop: '0px', paddingBottom: '0px', fontSize: '13px' }} 
                variant="filled">Please Wait...</Alert>}
              {this.state.errorMsg && <Alert severity="error" sx={{ my: 1, paddingTop: '0px', paddingBottom: '0px', fontSize: '13px' }} 
                variant="filled" onClose={() => this.setState({ errorMsg: '' })}>{this.state.errorMsg}</Alert>}
            </Box>
          </td></tr>
        </tbody></FullTable>
      </CenterPage>
    );
  }
}
