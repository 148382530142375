import React, { Component } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MonetPenny from '../graphics/MonetPenny.png';

export default class Connect extends Component {
  render() {
    return (
      <Container>
        <Box sx={{ mx: 'auto' }}>
          <Typography variant="h4" sx={{ fontWeight: 'medium', fontFamily: 'Satisfy', mb: 2, mt: 4, textAlign: 'left' }}>
            <img src={MonetPenny} alt="" width="60px" style={{ marginRight: '20px' }} />
            Connect
          </Typography>
          <hr/>

          <p>
            Questions? Comments? Connect with us:
            <br/> 
            (Page Under Development)
          </p>
        </Box>
      </Container>
    );
  }
}
