import styled from 'styled-components';
import SteelRepeat from '../graphics/SteelRepeat.png';

const TitleHeight = "110px";
const MinimumWidth = "350px";

export const CenterContent = styled.div`
  min-width: ${MinimumWidth};
  width: calc(100vw - 20px);
  height: calc(100vh - ${TitleHeight});
  margin: 0px auto;
  padding: 0px;
`;

export const CenterPage = styled.div`
  min-width: 350px;
  max-width: 500px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
`;

export const ContentPane = styled.div`
  background: black;
  position: fixed;
  left: 0px;
  top: ${p => p.showNav ? {TitleHeight} : '0px'};
  width: 100%;
  margin: 0 auto;
  height: ${p => p.showNav ? 'calc(100vh - {TitleHeight})' : '100vh'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  overflow-x: hidden;
  transition: width 0.2s;
`;

export const CenterDiv = styled.div`
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
`;

export const TextBox = styled.input`
  width: ${p => p.width ? p.width : '200px'};
  height: ${p => p.height ? p.height : '28px'};
  padding-left: 3px;
  padding-right: 3px;
  background-color: white;
  border: 1px solid lightgray;
  box-shadow: 2px 2px 2px #555469;
  &:focus {
    border: 1px solid blue;
    outline: none;
  }
  margin: 2px 0px 2px 0px;
  
  &::placeholder {
    padding-left: 2px;
    font-size: 10px;
  }
`;

export const TextArea = styled.textarea`
  width: ${p => p.width ? p.width : '200px'};
  height: ${p => p.height ? p.height : '50px'};
  padding: 5px;
  background-color: white;
  resize: none;
  line-height: 1;
  border: 1px solid lightgray;
  box-shadow: 2px 2px 2px #555469;
  &:focus {
    border: 1px solid blue;
    outline: none;
  }
`;

export const Select = styled.select`
  font-size: 12px;
  width: ${p => p.width ? p.width : '200px'};
  height: 28px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: white;
  border: 1px solid blue;
  box-shadow: 2px 2px 2px #555469;
  &:focus {
    border: 1px solid #3fd947;
    box-shadow: 2px 2px 2px #759c77;
    outline: none;
  }
  margin: 2px 0px 2px 0px;
  
  &::placeholder {
    padding-left: 2px;
    font-size: 10px;
  }
`;

export const FullTable = styled.table`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
`;

export const CoinFont = styled.span`
  font-family: Oswald;
  font-size: 24px;
  margin-left: 6px;
  color: #3fd947;
`;

export const CoinFontSmall = styled.span`
  font-family: Oswald;
  font-size: 14px;
  margin-left: 6px;
  color: #3fd947;
`;

export const CoinAmount = styled.span`
  font-family: Acme;
  font-size: 40px;
  color: #0f71a6;
`;

export const CoinAmountSmall = styled.span`
  font-family: Acme;
  font-size: 18px;
  color: #0f71a6
`;

export const MidHeader = styled.span`
  text-align: right;
  font-family: Acme;
  font-size: 16px;
`;

export const TableHeader = styled.span`
  text-align: right;
  font-family: Verdana;
  font-size: 14px;
`;

export const PageTitle = styled.span`
  font-family: Satisfy;
  font-size: 32px;
`;

export const NavButton = styled.button`
  width: 280px;
  height: 80px;
  background: linear-gradient(to right bottom, #333333, #eeeeee, #555555);
  transition: 0.5s;
  background-size: 200% auto;
  border: 1px solid white;
  border-radius: 8px;
  color: #454545;
  padding: 6px;
  margin: 2px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: Acme;
  font-size: 32px;
  &:hover {
    background-position: right center;
    border: 1px solid blue;
  }
  &:active {
    transition: 0s;
    background: linear-gradient(to right bottom, gray, black, gray);
    border: 2px 2px 2px #4d598a;
    color: white;
    outline: none;
  }
  &:disabled {
    color: #777777;
    background: #555555;
    border: 1px solid #333333;
    box-shadow: 2px 2px 2px #222222;
  }
`;

export const Button = styled.button`
  width: ${p => p.width ? p.width : '130px'};
  height: ${p => p.height ? p.height : '28px'};
  background: linear-gradient(to right bottom, #333333, #eeeeee, #555555);
  transition: 0.5s;
  border: 1px solid white;
  border-radius: 3px;
  color: #454545;
  margin: 2px 0px 2px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: Verdana;
  font-size: 12px;
  box-shadow: 2px 2px 2px #555469;
  &:hover {
    background: linear-gradient(to right bottom, white, silver, #2e2e2e);
    border: 1px solid blue;
  }
  &:active {
    transition: 0s;
    background: linear-gradient(to right bottom, gray, black, gray);
    border: 2px 2px 2px #4d598a;
    color: white;
    outline: none;
  }
  &:disabled {
    color: #777777;
    background: #555555;
    border: 1px solid #333333;
    box-shadow: 2px 2px 2px #222222;
  }
`;

export const Link = styled.span`
  margin-right: 10px
  font-family: Verdana;
  font-size: ${p => p.fontSize ? p.fontSize : '12px'};
  color: ${p => p.color ? p.color : '#b0e7ff'};
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: yellow;
  }
  &:active {
    color: white;
  }
`;

export const SmallLink = styled.span`
  font-family: Verdana;
  font-size: ${p => p.fontSize ? p.fontSize : '11px'};
  color: ${p => p.color ? p.color : '#999999'};
  cursor: pointer;
  margin-right: 5px;
  &:hover {
    color: #3fd947;
    text-decoration: underline;
  }
  &:active {
    color: #0f71a6;
    text-decoration: underline;
  }
`;

export const SearchText = styled.span`
  font-family: Verdana;
  font-size: 10px;
  color: #777777;
  padding-right: 10px;
`;

export const SpacerDiv = styled.div`
  height: ${p => p.height ? p.height : '15px'};
`;

export const HeaderTable = styled.table`
  width: 100%;
  height: 30px;
  margin: 0px;
  padding: 0px;
`;

export const LeftTD = styled.td`
  text-align: left;
`;

export const RightTD = styled.td`
  text-align: right;
`;

export const CenterTD = styled.td`
  text-align: center;
`;

export const SmallText = styled.span`
  font-family: Verdana;
  font-size: 12px;
`;

export const P = styled.div`
  font-family: 'Josefin Sans';
  font-size: 16px;
  font-weight: bold;
`;

export const Error = styled.span`
  color: red;
  font-size: 10px;
  white-space: pre-line;
`;

export const Success = styled.span`
  color: green;
  font-size: 10px;
  white-space: pre-line;
`;

export const Red = styled.span`
  color: red;
`;

export const Green = styled.span`
  color: green;
`;

export const ActivityRow = styled.tr`
  font-size: 12px;
  height: ${p => p.height ? p.height : '50px'};
  background-color: ${p => p.background ? p.background : 'white'};
  color: ${p => p.color ? p.color : 'black'};
  border: 1px solid lightgray;
  box-shadow: 2px 2px 2px #a7b3c7;
  &:focus {
    border: 1px solid #37426e;
    box-shadow: 2px 2px 2px #4d598a;
    outline: none;
  }
  margin: 2px 0px 2px 0px;
`;

export const ArtTitle = styled.div`
  z-index: 9000;
  position: fixed;
  font-size: 12px;
  font-family: Acme;
  top: 15px;
  left: 60px;
`;

export const MenuStrip = styled.table`
  width: 100%;
  height: 70px;
  background-color: gray;
  background-image: url(${SteelRepeat});
  background-repeat: repeat;
  background-color: black;
  border-top: 2px solid #222222;
  border-bottom: 2px solid #222222;
  color: white;
  z-index: 9000;
  position: fixed;
  top: 0px;
  left: 0px;
`;

export const CornerMenu = styled.div`
  z-index: 9999;
  position: fixed;
  top: 15px;
  right: 25px;
  cursor: pointer;
  &: hover {
    color: #a8caff;
  }
  &: active {
    color: #415d8a
  }
`;

export const CornerLogo = styled.div`
  z-index: 9000;
  position: fixed;
  top: 13px;
  left: 13px;
  cursor: pointer;
`;

export const LoadButton = styled.div`
  z-index: 9000;
  position: fixed;
  bottom: 10px;
  right: 15px;
  cursor: pointer;
  &: hover {
    color: #a8caff;
  }
  &: active {
    color: #415d8a
  }
`;

export const SearchButton = styled.div`
  z-index: 9000;
  position: fixed;
  bottom: 10px;
  left: 15px;
  cursor: pointer;
  &: hover {
    color: #a8caff;
  }
  &: active {
    color: #415d8a
  }
`;