import React, { Component } from 'react';
import { ModalMenu } from './ModalMenu';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import VideoPlayer from "react-background-video-player";
import ArtBackground from '../graphics/ArtBackground.mp4';
import styled from 'styled-components';
import NeonLogo from '../graphics/NeonLogo.png';
import MonetPennyTextNeon from '../graphics/MonetPennyTextNeon.png';
import SteelRepeat from '../graphics/SteelRepeat.png';
import MonetPenny from '../graphics/MonetPenny.png';
import MonetPennyText from '../graphics/MonetPennyText.png';
import SolanaIcon from '../graphics/SolanaIcon.png';
import SerumIcon from '../graphics/SerumIcon.png';
import PhantomIcon from '../graphics/PhantomIcon.png';
import WoodGradient from '../graphics/WoodGradient.jpg';
import { CornerLogo, CornerMenu } from '../helpers/LayoutComponents';

const LogoDisplay = styled.div`
  z-index: 9000;
  width: 100%;
`;

const MenuStrip = styled.table`
  width: 100%;
  height: 70px;
  background-color: gray;
  background-image: url(${SteelRepeat});
  background-repeat: repeat;
  background-color: black;
  border-top: 2px solid #222222;
  border-bottom: 2px solid #222222;
  color: white;
  z-index: 9000;
  position: fixed;
  top: 0px;
  left: 0px;
`;

const CenterPage = styled.div`
  position: absolute;
  top: calc(50vh);
  left: calc(50vw);
`;

const VideoContainer = styled.table`
  padding: 0;
  margin: 0;
  height: calc(100vh);
  max-width: calc(100vw);
`;

const LogoCenter = styled.img`
  position: absolute;
  top: -150px;
  left: -150px;
  transition: transform 1.5s ease-in-out;
  &: hover {
    transform: rotate(360deg);
  }
  &: active {
    filter: hue-rotate(180deg);
    -webkit-filter: hue-rotate(180deg);
  }
`;

const TextCenter = styled.img`
  cursor: pointer;
  position: absolute;
  top: 175px;
  left: -50px;
  transition: transform 0.5s ease-in-out;
  &: hover {
    transform: scale(1.5); 
  }
  &: active {
    filter: hue-rotate(180deg);
    -webkit-filter: hue-rotate(180deg);
  }
`;

// -------------------------------------------------------------------------
// This section contains code to handle scrolling events on the page
// -------------------------------------------------------------------------

// disable scroll if var = true
var disableScroll = false;

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {33: 1, 34: 1, 35: 1, 36: 1, 37: 1, 38: 1, 39: 1, 40: 1};

// function that we will assign from the constructor of Home to be called onScroll
var scrollFunction = null;

function preventDefault(e) {
  if(disableScroll)
    e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; } 
  }));
} catch(e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to check for scroll events
function onScroll() {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);

  document.addEventListener('scroll', () => { scrollFunction(window.scrollY) });
}

// -------------------------------------------------------------------------

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoOpacity: 1,
      menuOpacity: 0,
      windowWidth: window.innerWidth,
      scrollTo: props.location.state,
      showMenu: false,
      didScroll: true,
    }

    // bind so that child component can call without issue
    this.navigateToPage = this.navigateToPage.bind(this);

    // listen for scroll events and handle
    scrollFunction = this.handleVideoFade.bind(this);
    onScroll();
  }

  componentDidUpdate(props) {
    const selected = props.history.location.hash;
    if(this.state.didScroll === false && selected && selected.length > 0) {
      const elem = document.querySelector(selected);
      elem && elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.setState({didScroll: true});
    }
  }

  componentDidMount() { 
    window.addEventListener('resize', this.handleResize); 
    if(this.state.scrollTo) {
      const elem = document.getElementById(this.state.scrollTo);
      elem && elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  componentWillUnmount() { window.removeEventListener('resize', this.handleResize); }

  handleResize = () => { this.setState({ windowWidth: window.innerWidth }) };

  navigateToPage(pageName) {
    disableScroll = false;
    this.setState({showMenu: false, didScroll: pageName === "close", 
      videoOpacity: (pageName === "/" || pageName === "/#home") ? 1 : 0,
      menuOpacity: (pageName === "/" || pageName === "/#home") ? 0 : 1
    });

    if(pageName !== "close")
      this.props.history.push(pageName);
    else 
      this.handleVideoFade(0);
  }

  showMenu() {
    disableScroll = true;
    this.setState({showMenu: true});
  }

  handleVideoFade(scrollTop) {
    // calculate percentage of video to fade
    const viewportHeight = window.innerHeight;

    // prevent strange behavior if scroll to top or bottom
    if((window.scrollY === 0 && scrollTop < 0) || (window.scrollY === viewportHeight && viewportHeight < scrollTop)) { return; }
    const percentage = Math.min(100, Math.max(0, Math.round( scrollTop / ((viewportHeight) / 100) )));

    // now apply fade with animation
    var exponential = 0;
    if(percentage > 15 && percentage < 30)
      exponential = percentage;
    if(percentage >= 30)
      exponential = percentage + (percentage - 30) * (1.3); 

    // apply fade in for menu
    var fadeIn = (percentage < 15) ? 0 : ((percentage > 40) ? 1 : (percentage / 40));

    this.setState({videoOpacity: Math.max(0, Math.min(1, 1 - (exponential / 100))), menuOpacity: fadeIn});
  }

  render () {
    return (
      <Container>
        {this.state.showMenu && (<ModalMenu navigateTo={this.navigateToPage} />)}
        <MenuStrip style={{opacity: this.state.menuOpacity}}><tbody><tr><td>
          <CornerLogo>
            <img src={MonetPenny} alt="" width="40px" style={{marginRight: '10px'}} />
            <img src={MonetPennyText} alt="" width="80px" style={{marginRight: '10px'}} />
            {this.state.message}
          </CornerLogo>
        </td></tr></tbody></MenuStrip>
        <CornerMenu>
          <MenuIcon style={{fontSize: '40px'}} onClick={()=> this.showMenu()} />
        </CornerMenu>
        <Box id="home" sx={{ mx: 'auto', opacity: this.state.videoOpacity }}>
          <VideoContainer>
            <tbody><tr><td>
              <VideoPlayer
                id="videoplayer"
                height="100%"
                minHeight="600px"
                className="video"
                src={ArtBackground}
                autoPlay={true}
                muted={true}
                containerWidth={this.state.windowWidth}
              />
              <LogoDisplay>
                <CenterPage>
                  <TextCenter src={MonetPennyTextNeon} alt="" width="100px" height="50px" onClick={() => this.navigateToPage("#about")} />
                  <LogoCenter src={NeonLogo} alt="" width="300px" height="300px" onClick={() => this.navigateToPage("#about")} />
                </CenterPage>
              </LogoDisplay>
            </td></tr></tbody>
          </VideoContainer>
        </Box>

        <Box id="about" sx={{height: '70px'}} />
        <Box sx={{ mx: 'auto', minHeight: '600px' }}>
          <Typography variant="h4" sx={{ fontWeight: 'medium', fontFamily: 'Satisfy', mb: 2, mt: 4, textAlign: 'center' }}>
            About MonetPenny
          </Typography>
          <hr/>
          <p style={{textAlign: 'center'}}>
            MonetPenny is a new cryptocurrency that is planned to launch later in 2022, based off of the &nbsp; 
            <a href="https://solana.com/" target="_blank" rel="noopener noreferrer">Solana Blockchain</a> and technology.
          </p>

          <p style={{textAlign: 'center'}}>
            Additional information will be made available, once the organization is built out and we have a product available
            for launch.
          </p>
        </Box>

        <Box id="resources" sx={{height: '70px'}} />
        <Box sx={{ mx: 'auto', mb: 2, minHeight: '600px' }}>
          <Typography variant="h4" sx={{ fontWeight: 'medium', fontFamily: 'Satisfy', mb: 2, mt: 4, textAlign: 'center' }}>
            Resources
          </Typography>
          <hr/>
          <Grid container gap={2}>
            <Box sx={{ maxWidth: '350px', mx: 'auto', mt:2, border: 1, borderRadius: 2, 
              backgroundImage: `url(${WoodGradient})`, color: 'white', borderColor: '#555555', p: 4 }}>
              <Grid container>
                <img src={SolanaIcon} alt="" width="50px" style={{ marginRight: '10px' }} />
                <a href="https://solana.com/" target="_blank" rel="noopener noreferrer">
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mt: '7px', textAlign: 'left', color: 'white' }}>Solana</Typography>
                </a>
                <br/>
                <Typography variant="subtitle2" sx={{ fontWeight: 'medium', mt: '10px', mb: '20px', textAlign: 'left', color: 'yellow' }}>
                  Cryptocurrency, Blockchain Technology
                </Typography>
                Solana is a public blockchain platform with smart contract functionality. Its native cryptocurrency 
                is SOL. Solana claims to offer faster transaction times and lower costs than its main competitor, Ethereum.
              </Grid>
            </Box>

            <Box sx={{ maxWidth: '350px', mx: 'auto', mt:2, border: 1, borderRadius: 2, 
              backgroundImage: `url(${WoodGradient})`, color: 'white', borderColor: '#555555', p: 4 }}>
              <Grid container >
                <img src={SerumIcon} alt="" width="50px" style={{ marginRight: '10px' }} />
                <a href="https://www.projectserum.com/" target="_blank" rel="noopener noreferrer">
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mt: '7px', textAlign: 'left', color: 'white' }}>Serum</Typography>
                </a>
                <br/>
                <Typography variant="subtitle2" sx={{ fontWeight: 'medium', mt: '10px', mb: '20px', textAlign: 'left', color: 'yellow' }}>
                  Platform to Buy, Sell, Trade (MonetPenny)
                </Typography>
                Serum is a protocol and ecosystem that brings unprecedented speed and low transaction costs to decentralized 
                finance. It is built on Solana and is completely permissionless. Ecosystem partners can compose with Serum’s 
                on-chain central limit orderbook to share liquidity and power markets-based features for institutional and 
                retail users.
              </Grid>
            </Box>

            <Box sx={{ maxWidth: '350px', mx: 'auto', mt:2, border: 1, borderRadius: 2, 
              backgroundImage: `url(${WoodGradient})`, color: 'white', borderColor: '#555555', p: 4 }}>
              <Grid container >
                <img src={PhantomIcon} alt="" width="50px" style={{ marginRight: '10px' }} />
                <a href="https://phantom.app/" target="_blank" rel="noopener noreferrer">
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mt: '7px', textAlign: 'left', color: 'white' }}>Phantom Wallet</Typography>
                </a>
                <br/>
                <Typography variant="subtitle2" sx={{ fontWeight: 'medium', mt: '10px', mb: '20px', textAlign: 'left', color: 'yellow' }}>
                  Digital Wallet for Cryptocurrency
                </Typography>
                Phantom is a digital wallet tailored for Solana and Ethereum, reimagined for DeFi, making it safe and easy to 
                store, send, receive, collect, and swap tokens on the Solana blockchain. Store your MonetPenny coins on Phantom!
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Container>
    );
  }
}
