import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { CenterContent, ContentPane } from './LayoutComponents';
import { checkRouteForString } from '../helpers/SessionHelper';
import HeaderComponent from '../components/HeaderComponent';
import Home from '../components/Home';
import Login from '../components/Login';
import About from '../components/About';
import Resources from '../components/Resources';
import Connect from '../components/Connect';
import RegisterArt from '../components/RegisterArt';
import Gallery from '../components/Gallery';
import SearchArt from '../components/SearchArt';
import SendCoins from '../components/SendCoins';
import ReceiveCoins from '../components/ReceiveCoins';
import Validate from '../components/Validate';
import Wallet from '../components/Wallet';
import Activity from '../components/Activity';
import ActivityPrint from '../components/ActivityPrint';
import PurchaseCoins from '../components/PurchaseCoins';
import LoginState from '../helpers/LoginState';
import ForgotPW from '../components/ForgotPW';
import RegisterUser from '../components/RegisterUser';

const PageRoutes = () => {
  /* const isMobileDevice = /Mobi/i.test(window.navigator.userAgent); */
  const history = useHistory();
  const lastTS = localStorage.getItem('lastTimeStamp');

  if (!lastTS) {
    // no timestamp found (logout)
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('lastTimeStamp');
    LoginState.loggedIn = false;
  } else {
    const elapsedSeconds = (Date.now() - lastTS) / 1000;
    if (elapsedSeconds > 60 * 60 * 1) { // 1 hour
      // time exceeded (logout)
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('lastTimeStamp');
      LoginState.loggedIn = false;

      if (!history.location.pathname.includes("validate")) {
        history.push("/");
      }
    } else {
      // update elapsed time
      localStorage.removeItem('lastTimeStamp');
      localStorage.setItem('lastTimeStamp', Date.now());
    }
  }

  // the home page will not use the header component
  if(history.location.pathname.toLowerCase() === "/") {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
      </Switch>
    );
  }
  else {
    return (
      <>
        {(!checkRouteForString('activityprint', history.location.pathname) && !checkRouteForString('gallery', history.location.pathname)) && (
          <HeaderComponent history={history} />
        )}
        <ContentPane showNav={!checkRouteForString('activityprint', history.location.pathname) && !checkRouteForString('gallery', history.location.pathname)}>
          <CenterContent>
            <Switch>
              {LoginState.loggedIn ? (<>
                <Route exact path="/about" component={About} />
                <Route exact path="/resources" component={Resources} />
                <Route exact path="/connect" component={Connect} />
                <Route exact path="/register" component={RegisterArt} />
                <Route exact path="/send" component={SendCoins} />
                <Route exact path="/receive" component={ReceiveCoins} />
                <Route exact path="/gallery/:id" component={Gallery} />
                <Route exact path="/search" component={SearchArt} />
                <Route exact path="/wallet" component={Wallet} />
                <Route exact path="/activity" component={Activity} />
                <Route exact path="/activityprint/:search" component={ActivityPrint} />
                <Route exact path="/purchase" component={PurchaseCoins} />
                <Route exact path="/" component={Home} />
              </>) : (<>
                <Route exact path="/login" component={Login} />
                <Route exact path="/forgotpw" component={ForgotPW} />
                <Route exact path="/validate/:id" component={Validate} />
                <Route exact path="/registeruser" component={RegisterUser} />
                <Route exact path="/about" component={About} />
                <Route exact path="/resources" component={Resources} />
                <Route exact path="/connect" component={Connect} />
                <Route exact path="/register" component={RegisterArt} />
                <Route exact path="/gallery/:id" component={Gallery} />
                <Route exact path="/search" component={SearchArt} />
                <Route exact path="/" component={Home} />
              </>)}
            </Switch>
          </CenterContent>
        </ContentPane>
      </>
    );
  }
};

export default PageRoutes;
