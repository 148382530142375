import React, { Component } from 'react';
import LoginState from '../helpers/LoginState';
import { ModalMenu } from './ModalMenu';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import MonetPenny from '../graphics/MonetPenny.png';
import MonetPennyText from '../graphics/MonetPennyText.png';
import { MenuStrip, CornerLogo, CornerMenu } from '../helpers/LayoutComponents';

export default class HeaderComponent extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      windowWidth: window.innerWidth,
      showMenu: false,
    };

    // bind so that child component can call without issue
    this.navigateToPage = this.navigateToPage.bind(this);
  }

  componentDidMount() { window.addEventListener('resize', this.handleResize); this.handleResize(); }

  componentWillUnmount() { window.removeEventListener('resize', this.handleResize); }

  handleResize = () => { this.setState({ windowWidth: window.innerWidth }) };

  logout() {
    this.setState({anchorRef2: null});
    LoginState.loggedIn = false;
    LoginState.userName = '';
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.props.history.push('/login');
  }

  navigateToPage(pageName) {
    this.setState({showMenu: false, didScroll: false });
    if(pageName === "close") { return; }
    else { this.props.history.push(pageName.split('#')[0], pageName.split('#')[1]); }
  }

  showMenu() {
    this.setState({showMenu: true});
  }

  render() {
    return (<>
      {this.state.showMenu && (<ModalMenu navigateTo={this.navigateToPage} />)}
      <MenuStrip style={{opacity: this.state.menuOpacity}}><tbody><tr><td>
        <CornerLogo style={{cursor: 'pointer'}} onClick={() => this.props.history.push(LoginState.loggedIn ? "/wallet" : "/")}>
          <img src={MonetPenny} alt="" width="40px" style={{marginRight: '10px'}} />
          <img src={MonetPennyText} alt="" width="80px" style={{marginRight: '10px'}} />
          {this.state.message}
        </CornerLogo></td></tr></tbody>
      </MenuStrip>
      <CornerMenu>
          <MenuIcon style={{fontSize: '40px'}} onClick={()=> this.showMenu()} />
        </CornerMenu>
      <Box sx={{height: '70px'}} />
    </>);
  }
}
